import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Profile } from 'src/app/auth/models';

@Component({
  selector: 'app-created-by',
  templateUrl: './created-by.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatedByComponent implements OnInit {
  @Input() name!: string;
  @Input() avatar!: string;
  @Input() showName = true;
  @Input() sizes = 90;

  get firstletter() {
    return this.name.slice(0, 1).toLocaleLowerCase();
  }

  constructor() {}

  ngOnInit(): void {}
}
