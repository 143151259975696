// tslint:disable:max-line-length
import { createAction, props } from '@ngrx/store';
import { PagedOverview } from 'src/app/core/models';
import { Opinion, UpdateOpinionData, VoteDirection } from '../../models';

export const LoadOpinion = createAction('[Opinions] Load Opinion', props<{ id: number }>());
export const LoadOpinionSuccess = createAction('[Opinions] Load Opinion Success', props<{ opinion: Opinion }>());
export const LoadOpinionFail = createAction('[Opinions] Load Opinion Fail', props<{ error: any }>());

export const LoadOpinions = createAction(
  '[Opinions] Load Opinions',
  props<{ take?: number; skip?: number; category?: number; unvoted?: boolean; personalized?: boolean }>()
);
export const LoadOpinionsSuccess = createAction('[Opinions] Load Opinions Success', props<{ opinions: PagedOverview<Opinion> }>());
export const LoadOpinionsFail = createAction('[Opinions] Load Opinions Fail', props<{ error: any }>());

export const LoadMoreOpinions = createAction('[Opinions] Load MORE Opinions');
export const LoadMoreOpinionsSuccess = createAction('[Opinions] Load MORE Opinions Success', props<{ opinions: PagedOverview<Opinion> }>());

export const UpdateOpinion = createAction('[Opinions] Update Opinion', props<{ id: number; opinionData: UpdateOpinionData }>());
export const UpdateOpinionSuccess = createAction('[Opinions] Update Opinion Success', props<{ opinion: Opinion }>());
export const UpdateOpinionFail = createAction('[Opinions] Update Opinion Fail', props<{ error: any }>());

export const CreateOpinion = createAction('[Opinions] Create Opinion', props<{ opinionData: UpdateOpinionData }>());
export const CreateOpinionSuccess = createAction('[Opinions] Create Opinion Success', props<{ opinion: Opinion }>());
export const CreateOpinionFail = createAction('[Opinions] Create Opinion Fail', props<{ error: any }>());

export const DeleteOpinion = createAction('[Opinions] Delete Opinion', props<{ id: number }>());
export const DeleteOpinionSuccess = createAction('[Opinions] Delete Opinion Success', props<{ opinion: Opinion }>());
export const DeleteOpinionFail = createAction('[Opinions] Delete Opinion Fail', props<{ error: any }>());

export const CloseOpinion = createAction('[Opinions] Close Opinion', props<{ id: number }>());
export const CloseOpinionSuccess = createAction('[Opinions] Close Opinion Success', props<{ opinion: Opinion }>());
export const CloseOpinionFail = createAction('[Opinions] Close Opinion Fail', props<{ error: any }>());

export const ClearOpinions = createAction('[Opinions] Clear Opinions');

export const CastVote = createAction('[Opinions] Cast Vote', props<{ id: number; direction: VoteDirection }>());
export const CastVoteSuccess = createAction('[Opinions] Cast Vote Success', props<{ opinion: Opinion }>());
export const CastVoteFail = createAction('[Opinions] Cast Vote Fail', props<{ error: any }>());
