// tslint:disable:max-line-length
import { createAction, props } from '@ngrx/store';
import { OpinionComment } from '../../models';

export const LoadComments = createAction('[Opinions] Load Comments for Opinion', props<{ id: number }>());
export const LoadCommentsSuccess = createAction('[Opinions] Load Comments for Opinion Success', props<{ comments: OpinionComment[] }>());
export const LoadCommentsFail = createAction('[Opinions] Load Comments for Opinion Fail', props<{ error: any }>());

export const CreateComment = createAction('[Opinions] Create Opinion Comment', props<{ id: number; comment: string }>());
export const CreateCommentSuccess = createAction('[Opinions] Create Opinion Comment Success', props<{ comment: OpinionComment }>());
export const CreateCommentFail = createAction('[Opinions] Create Opinion Comment Fail', props<{ error: any }>());

export const DeleteComment = createAction('[Opinions] Delete Opinion Comment', props<{ id: number; commentId: number }>());
export const DeleteCommentSuccess = createAction('[Opinions] Delete Opinion Comment Success', props<{ comment: OpinionComment }>());
export const DeleteCommentFail = createAction('[Opinions] Delete Opinion Comment Fail', props<{ error: any }>());

export const ClearComments = createAction('[Opinions] Clear Opinion Comments');
