import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/events.action';
import { switchMap, map, catchError, tap, withLatestFrom } from 'rxjs/operators';
import { EventsService } from '../../services';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Go } from '../../../store';
import { Store } from '@ngrx/store';
import { getEventsPage } from '../selectors';

@Injectable()
export class EventsEffects {
  constructor(
    private actions$: Actions,
    private service: EventsService,
    private router: Router,
    private sb: MatSnackBar,
    private store: Store
  ) {}

  LoadEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadEvent),
      switchMap(({ id }) =>
        this.service.getEvent(id).pipe(
          map(event => actions.LoadEventSuccess({ event })),
          catchError(error => of(actions.LoadEventFail({ error })))
        )
      )
    );
  });

  LoadEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadEvents),
      switchMap(({ skip, take, category }) =>
        this.service.getEvents(skip, take, category).pipe(
          map(events => actions.LoadEventsSuccess({ events })),
          catchError(error => of(actions.LoadEventsFail({ error })))
        )
      )
    );
  });

  LoadMoreEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadMoreEvents),
      withLatestFrom(this.store.select(getEventsPage)),
      switchMap(([{}, pagedata]) =>
        this.service.getEvents((pagedata.current + 1) * pagedata.size, pagedata.size, undefined).pipe(
          map(events => actions.LoadMoreEventsSuccess({ events })),
          catchError(error => of(actions.LoadEventsFail({ error })))
        )
      )
    );
  });

  CreateEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CreateEvent),
      switchMap(({ eventData }) =>
        this.service.createEvent(eventData).pipe(
          map(event => actions.CreateEventSuccess({ event })),
          catchError(error => of(actions.CreateEventFail({ error })))
        )
      )
    );
  });

  UpdateEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.UpdateEvent),
      switchMap(({ id, eventData }) =>
        this.service.updateEvent(id, eventData).pipe(
          map(event => actions.UpdateEventSuccess({ event })),
          catchError(error => of(actions.UpdateEventFail({ error })))
        )
      )
    );
  });

  DeleteEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.DeleteEvent),
      switchMap(({ id }) =>
        this.service.deleteEvent(id).pipe(
          map(event => actions.DeleteEventSuccess({ event })),
          catchError(error => of(actions.DeleteEventFail({ error })))
        )
      )
    );
  });

  updateEventSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.UpdateEventSuccess),
      tap(({ event }) => {
        this.sb.open('Evenement geüpdatet!', '');
      }),
      map(({ event }) => Go({ path: event.url }))
    );
  });

  createEventSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CreateEventSuccess),
      tap(({ event }) => {
        this.sb.open('Evenement aangemaakt!', '');
      }),
      map(({ event }) => Go({ path: event.url }))
    );
  });

  deleteEventSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.DeleteEventSuccess),
      tap(({ event }) => {
        this.sb.open('Evenement verwijderd!', '');
      }),
      map(({ event }) => Go({ path: ['/', 'agenda'] }))
    );
  });
}
