import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabBarComponent implements OnInit {
  @Input() actionTabUrl: string = '/meningen/nieuw';
  @Input() actionTabLabel: string = 'Deel jou mening';

  constructor() {}

  ngOnInit(): void {}
}
