import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Event, UpdateEventData } from '../models/event.model';
import { PagedOverview } from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getEvents(skip: number = 0, take: number = 0, category: number = 0) {
    return this.http.get<PagedOverview<Event>>(`${this.BASE_URL}api/events?take=${take}&skip=${skip}&category=${category}`);
  }

  getEvent(id: number) {
    return this.http.get<Event>(`${this.BASE_URL}api/events/${id}`);
  }

  updateEvent(id: number, data: UpdateEventData) {
    const formData: FormData = new FormData();
    if (data.image instanceof Blob) {
      formData.append('image', data.image);
    } else {
      formData.append('image', <Blob>(null as unknown));
    }
    formData.append('title', data.title);
    formData.append('text', data.text);
    formData.append('helpText', data.helpText);
    formData.append('location', data.location);
    formData.append('startDate', data.startDate?.toString());
    formData.append('endDate', data.endDate?.toString());

    data.categories.forEach((category, i) => {
      formData.append(`categories[${i}].id`, category.id.toString());
      formData.append(`categories[${i}].title`, category.title);
    });
    formData.append('id', data.id?.toString());
    return this.http.put<Event>(`${this.BASE_URL}api/events/${id}`, formData);
  }

  deleteEvent(id: number) {
    return this.http.delete<Event>(`${this.BASE_URL}api/events/${id}`);
  }

  createEvent(data: UpdateEventData) {
    const formData: FormData = new FormData();
    if (data.image instanceof Blob) {
      formData.append('image', data.image);
    } else {
      formData.append('image', <Blob>(null as unknown));
    }
    formData.append('title', data.title);
    formData.append('text', data.text);
    formData.append('location', data.location);
    formData.append('startDate', data.startDate?.toString());
    formData.append('endDate', data.endDate?.toString());

    data.categories.forEach((category, i) => {
      formData.append(`categories[${i}].id`, category.id.toString());
      formData.append(`categories[${i}].title`, category.title);
    });
    formData.append('id', data.id?.toString());
    return this.http.post<Event>(`${this.BASE_URL}api/events`, formData);
  }
}
