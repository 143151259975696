<article class="notification-card card-list shadow--right" [class.set--unreaded]="!notification.isRead" [ngClass]="'type--' + type">
  <main class="card-list__content notification-card__content p-3">
    <h1 class="type-body-xs notification-card__type mb-1">{{ type }}</h1>

    <div class="card-list__text notification-card__text">{{ notification.message }}</div>
    <footer class="mt-2 notification-card__footer type-body-xxs text-muted">
      {{ notification.date | date }}
    </footer>
    <button class="card__block-link" (click)="read(notification)">
      <span class="sr-only">{{ type }}</span>
      <span class="link-icon">
        <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.2085 16.1604L20.2556 8.66564L13.4171 0.398193L0.560892 0.398192L0.560891 16.258L13.2085 16.1604Z" class="svg-bg" />
          <path
            class="svg-arrow"
            d="M8.79325 10.538L8.10021 9.8509L9.91946 8.03165H5.65662L5.65662 7.02195L9.91946 7.02195L8.10021 5.20569L8.79325 4.51562L11.8044 7.5268L8.79325 10.538Z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.2556 8.66564L13.4171 0.398193L0.560892 0.398192L0.560891 16.258L13.2085 16.1604L20.2556 8.66564ZM12.8421 15.322L19.1344 8.63001L13.0212 1.23941L1.40211 1.23941L1.40211 15.4103L12.8421 15.322Z"
            fill="black"
          />
          <path
            d="M0.560641 16.2589L0.560641 19.7763L13.4168 19.7763L20.2566 11.5064L20.2566 8.66774L20.2566 8.6665L20.2554 8.6665L13.2082 16.1612L0.560641 16.2589Z"
            fill="black"
          />
        </svg>
      </span>
    </button>
  </main>
</article>
