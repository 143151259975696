// tslint:disable:max-line-length
import { createAction, props } from '@ngrx/store';
import { PagedOverview } from 'src/app/core/models';
import { Initiative, UpdateInitiativeData, VoteDirection } from '../../models';

export const LoadInitiative = createAction('[Initiatives] Load Initiative', props<{ id: number }>());
export const LoadInitiativeSuccess = createAction('[Initiatives] Load Initiative Success', props<{ initiative: Initiative }>());
export const LoadInitiativeFail = createAction('[Initiatives] Load Initiative Fail', props<{ error: any }>());

export const LoadInitiatives = createAction(
  '[Initiatives] Load Initiatives',
  props<{ take?: number; skip?: number; category?: number; unvoted?: boolean; personalized?: boolean }>()
);
export const LoadInitiativesSuccess = createAction(
  '[Initiatives] Load Initiatives Success',
  props<{ initiatives: PagedOverview<Initiative> }>()
);
export const LoadInitiativesFail = createAction('[Initiatives] Load Initiatives Fail', props<{ error: any }>());

export const LoadMoreInitiatives = createAction('[Initiatives] Load MORE Initiatives');
export const LoadMoreInitiativesSuccess = createAction(
  '[Initiatives] Load MORE Initiatives Success',
  props<{ initiatives: PagedOverview<Initiative> }>()
);

export const UpdateInitiative = createAction(
  '[Initiatives] Update Initiative',
  props<{ id: number; initiativeData: UpdateInitiativeData }>()
);
export const UpdateInitiativeSuccess = createAction('[Initiatives] Update Initiative Success', props<{ initiative: Initiative }>());
export const UpdateInitiativeFail = createAction('[Initiatives] Update Initiative Fail', props<{ error: any }>());

export const CreateInitiative = createAction('[Initiatives] Create Initiative', props<{ initiativeData: UpdateInitiativeData }>());
export const CreateInitiativeSuccess = createAction('[Initiatives] Create Initiative Success', props<{ initiative: Initiative }>());
export const CreateInitiativeFail = createAction('[Initiatives] Create Initiative Fail', props<{ error: any }>());

export const DeleteInitiative = createAction('[Initiatives] Delete Initiative', props<{ id: number }>());
export const DeleteInitiativeSuccess = createAction('[Initiatives] Delete Initiative Success', props<{ initiative: Initiative }>());
export const DeleteInitiativeFail = createAction('[Initiatives] Delete Initiative Fail', props<{ error: any }>());

export const CloseInitiative = createAction('[Initiatives] Close Initiative', props<{ id: number }>());
export const CloseInitiativeSuccess = createAction('[Initiatives] Close Initiative Success', props<{ initiative: Initiative }>());
export const CloseInitiativeFail = createAction('[Initiatives] Close Initiative Fail', props<{ error: any }>());

export const ClearInitiatives = createAction('[Initiatives] Clear Initiatives');

export const CastVote = createAction('[Initiatives] Cast Vote', props<{ id: number; direction: VoteDirection }>());
export const CastVoteSuccess = createAction('[Initiatives] Cast Vote Success', props<{ initiative: Initiative }>());
export const CastVoteFail = createAction('[Initiatives] Cast Vote Fail', props<{ error: any }>());

export const CreateInitiativeFromOpinion = createAction('[Initiatives] Create Initiative From Opinion', props<{ opinionId: number }>());
export const CreateInitiativeFromOpinionSuccess = createAction(
  '[Initiatives] Create Initiative From Opinion Success',
  props<{ initiative: Initiative }>()
);
export const CreateInitiativeFromOpinionFail = createAction('[Initiatives] Create Initiative From Opinion Fail', props<{ error: any }>());
