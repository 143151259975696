import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromInitiative from '../reducers/initiatives.reducer';
import * as fromRoot from '../../../store';
import * as fromAuth from '../../../auth/store';
import { Initiative } from '../../models';

export const getInitiativeState = createSelector(
  fromFeature.getInitiativesState,
  (state: fromFeature.InitiativeState) => state.initiatives
);

export const getInitiativesEntities = createSelector(getInitiativeState, fromInitiative.getInitiativesEntities);

export const getAllInitiatives = createSelector(getInitiativesEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

export const getInitiativesLoaded = createSelector(getInitiativeState, fromInitiative.getInitiativesLoaded);
export const getInitiativesLoading = createSelector(getInitiativeState, fromInitiative.getInitiativesLoading);
export const getInitiativeSaved = createSelector(getInitiativeState, fromInitiative.getInitiativeSaved);
export const getInitiativeSaving = createSelector(getInitiativeState, fromInitiative.getInitiativeSaving);
export const getInitiativesPage = createSelector(getInitiativeState, fromInitiative.getInitiativesPage);

export const getOrderedInitiatives = createSelector(getAllInitiatives, getInitiativesPage, (initiatives, page) => {
  let orderedInitiatives = initiatives.sort((a, b) => (new Date(b.createdOn) > new Date(a.createdOn) ? 1 : -1));
  if (page.filters['personalized']) {
    orderedInitiatives = initiatives.sort((a, b) => (b.score > a.score ? 1 : -1));
  }
  return orderedInitiatives;
});

export const getMyInitiatives = createSelector(getAllInitiatives, fromAuth.getAuthUser, (initiatives, usr) => {
  return initiatives?.filter(a => a.createdById === usr?.id);
});

export const getUnvotedInitiatives = createSelector(getAllInitiatives, fromAuth.getAuthUser, (initiatives, usr) => {
  return initiatives?.filter(a => !a.votes.mine);
});

export const getSelectedInitiative = createSelector(getInitiativesEntities, fromRoot.getRouterState, (entities, router): Initiative => {
  return router.state && entities[router.state.params?.['initiativeId'] || router.state.data?.['id']];
});

export const getSelectedInitiativeLoaded = createSelector(getSelectedInitiative, (c: Initiative) => {
  return c?.loaded;
});

export const getSelectedInitiativeLoading = createSelector(getSelectedInitiative, (c: Initiative) => {
  return c?.loading;
});
