// tslint:disable:max-line-length
import { createAction, props } from '@ngrx/store';
import { InitiativeComment } from '../../models';

export const LoadComments = createAction('[Initiatives] Load Comments for Initiative', props<{ id: number }>());
export const LoadCommentsSuccess = createAction(
  '[Initiatives] Load Comments for Initiative Success',
  props<{ comments: InitiativeComment[] }>()
);
export const LoadCommentsFail = createAction('[Initiatives] Load Comments for Initiative Fail', props<{ error: any }>());

export const CreateComment = createAction('[Initiatives] Create Initiative Comment', props<{ id: number; comment: string }>());
export const CreateCommentSuccess = createAction(
  '[Initiatives] Create Initiative Comment Success',
  props<{ comment: InitiativeComment }>()
);
export const CreateCommentFail = createAction('[Initiatives] Create Initiative Comment Fail', props<{ error: any }>());

export const DeleteComment = createAction('[Initiatives] Delete Initiative Comment', props<{ id: number; commentId: number }>());
export const DeleteCommentSuccess = createAction(
  '[Initiatives] Delete Initiative Comment Success',
  props<{ comment: InitiativeComment }>()
);
export const DeleteCommentFail = createAction('[Initiatives] Delete Initiative Comment Fail', props<{ error: any }>());

export const ClearComments = createAction('[Initiatives] Clear Initiative Comments');
