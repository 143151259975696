import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OpinionComment } from '../models';

@Injectable({
  providedIn: 'root',
})
export class OpinionCommentsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getOpinionComments(id: number) {
    return this.http.get<OpinionComment[]>(`${this.BASE_URL}api/opinions/${id}/comments`);
  }

  deleteOpinionComment(id: number, commentId: number) {
    return this.http.delete<OpinionComment>(`${this.BASE_URL}api/opinions/${id}/comments/${commentId}`);
  }

  createOpinionComment(id: number, comment: string) {
    return this.http.post<OpinionComment>(`${this.BASE_URL}api/opinions/${id}/comments`, { text: comment });
  }
}
