import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromStatusUpdates from '../reducers/status-updates.reducer';
import * as fromInitiatives from './initiatives.selector';
import { Initiative } from '../../models';

export const GetStatusUpdatestate = createSelector(
  fromFeature.getInitiativesState,
  (state: fromFeature.InitiativeState) => state.statusUpdates
);

export const GetStatusUpdatesEntities = createSelector(GetStatusUpdatestate, fromStatusUpdates.getInitiativeStatusUpdatesEntities);

export const getAllStatusUpdates = createSelector(GetStatusUpdatesEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

export const getOrderedStatusUpdates = createSelector(getAllStatusUpdates, StatusUpdates => {
  return StatusUpdates.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
});

export const GetStatusUpdatesLoadedFor = createSelector(GetStatusUpdatestate, fromStatusUpdates.getInitiativeStatusUpdatesLoadedFor);
export const GetStatusUpdatesLoadingFor = createSelector(GetStatusUpdatestate, fromStatusUpdates.getInitiativeStatusUpdatesLoadingFor);
export const GetStatusUpdatesSaved = createSelector(GetStatusUpdatestate, fromStatusUpdates.getInitiativeStatusUpdatesaved);
export const GetStatusUpdatesSaving = createSelector(GetStatusUpdatestate, fromStatusUpdates.getInitiativeStatusUpdatesaving);

export const getSelectedInitiativeStatusUpdatesLoaded = createSelector(
  GetStatusUpdatesLoadedFor,
  fromInitiatives.getSelectedInitiative,
  (loadedFor: number | undefined, o: Initiative) => {
    return o?.id === loadedFor;
  }
);

export const getSelectedInitiativeStatusUpdatesLoading = createSelector(
  GetStatusUpdatesLoadingFor,
  fromInitiatives.getSelectedInitiative,
  (loadingFor: number | undefined, o: Initiative) => {
    return o?.id === loadingFor;
  }
);
