import { InitiativeCommentsEffects } from './comments.effect';
import { ContributorsEffects } from './contributors.effect';
import { InitiativesEffects } from './initiatives.effect';
import { StatusUpdatesEffects } from './status-updates.effect';

export * from './comments.effect';
export * from './initiatives.effect';
export * from './contributors.effect';
export * from './status-updates.effect';

export const effects = [InitiativesEffects, InitiativeCommentsEffects, ContributorsEffects, StatusUpdatesEffects];
