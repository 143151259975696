import { createReducer, on } from '@ngrx/store';
import { Opinion } from '../../models';
import * as utility from '../../../utility';
import * as OpinionActions from '../actions/opinions.action';
import { PageData } from 'src/app/core/models';

export interface OpinionsState {
  entities: { [id: number]: Opinion };
  page: PageData;
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
  error?: any;
}

export const initialState: OpinionsState = {
  entities: {},
  page: { current: 0, pageCount: 0, resultCount: 0, size: 6, filters: { unvoted: true } },
  loaded: false,
  loading: false,
  saved: false,
  saving: false,
};

export const OpinionReducer = createReducer(
  initialState,
  on(OpinionActions.LoadOpinions, OpinionActions.LoadMoreOpinions, (state: OpinionsState) => {
    return {
      ...state,
      loading: true,
      mineLoaded: false,
    };
  }),
  on(OpinionActions.LoadOpinionsSuccess, OpinionActions.LoadMoreOpinionsSuccess, (state: OpinionsState, { opinions }) => {
    const entities = utility.ToEntities(opinions.items, 'id', state.entities);

    return {
      ...state,
      entities,
      page: opinions.page,
      loading: false,
      loaded: true,
      mineLoaded: true,
    };
  }),
  on(OpinionActions.LoadOpinionsFail, (state: OpinionsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      mineLoaded: false,
      error,
    };
  }),
  on(OpinionActions.LoadOpinion, (state: OpinionsState, { id }) => {
    const entity: Opinion = {
      ...state.entities[id],
      loading: true,
      loaded: false,
    };

    return {
      ...state,
      entities: {
        ...state.entities,
        [id]: entity,
      },
    };
  }),
  // on(OpinionActions.CastVote, (state: OpinionsState, { direction, id }) => {
  //   const entities = {
  //     ...state.entities,
  //     [id]: {
  //       ...state.entities[id],
  //       votes: {
  //         ...state.entities[id].votes,
  //         mine: direction,
  //       },
  //     },
  //   };
  //   return {
  //     ...state,
  //     entities,
  //   };
  // }),
  on(OpinionActions.LoadOpinionSuccess, OpinionActions.CastVoteSuccess, (state: OpinionsState, { opinion }) => {
    console.log('castvvotesucces', opinion);
    return {
      ...state,
      entities: {
        ...state.entities,
        [opinion.id]: opinion,
        // [opinion.id]: {
        //   ...opinion,
        //   votes: {
        //     ...state.entities[opinion.id].votes,
        //     mine: direction,
        //   }
        // },
      },
    };
  }),
  on(OpinionActions.CreateOpinion, OpinionActions.UpdateOpinion, OpinionActions.CloseOpinion, (state: OpinionsState) => {
    return {
      ...state,
      saved: false,
      saving: true,
    };
  }),
  on(
    OpinionActions.CreateOpinionSuccess,
    OpinionActions.UpdateOpinionSuccess,
    OpinionActions.CloseOpinionSuccess,
    (state: OpinionsState, { opinion }) => {
      const entities = {
        ...state.entities,
        [opinion.id]: opinion,
      };
      return {
        ...state,
        entities,
        saved: true,
        saving: false,
      };
    }
  ),
  on(OpinionActions.DeleteOpinionSuccess, (state: OpinionsState, { opinion }) => {
    const { [opinion.id]: removed, ...entities } = state.entities;
    return {
      ...state,
      entities,
      saved: true,
      saving: false,
    };
  }),
  on(OpinionActions.ClearOpinions, (state: OpinionsState) => {
    return {
      ...initialState,
    };
  })
);

export const getOpinionsEntities = (state: OpinionsState) => state.entities;
export const getOpinionsLoading = (state: OpinionsState) => state.loading;
export const getOpinionsLoaded = (state: OpinionsState) => state.loaded;
export const getOpinionSaved = (state: OpinionsState) => state.saved;
export const getOpinionSaving = (state: OpinionsState) => state.saving;
export const getOpinionsPage = (state: OpinionsState) => state.page;
