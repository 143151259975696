import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/notifications.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { NotificationsService } from '../../services';

@Injectable()
export class NotificationsEffects {
  constructor(private actions$: Actions, private service: NotificationsService) {}

  LoadNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadNotifications),
      switchMap(() =>
        this.service.getNotifications().pipe(
          map(notifications => actions.LoadNotificationsSuccess({ notifications })),
          catchError(error => of(actions.LoadNotificationsFail({ error })))
        )
      )
    );
  });

  MarkASRead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.MarkAsRead),
      switchMap(({ notification }) =>
        this.service.markAsRead(notification).pipe(
          map(notifications => actions.MarkAsReadSuccess({ notifications })),
          catchError(error => of(actions.MarkAsReadFail({ error })))
        )
      )
    );
  });
}
