<app-auth-layout [title]="prefix + '-titel' | fromDictionary" icon="inloggen">
  <fstr-dynamic-form form [appearance]="'fill'" [config]="formFields" (formSubmit)="handleSubmit($event)" [showSubmit]="false">
    <app-alert
      slot="form-alert"
      *ngIf="error$ | async as error"
      [status]="'type--danger'"
      [message]="getErrorMessage(error?.error) | fromDictionary"
      class="d-block mb-3"
    ></app-alert>

    <div slot="form-actions">
      <button type="submit" class="button button--primary" [disabled]="signingIn$ | async">
        <span>{{ prefix + '-formulier-verzenden-knop-tekst' | fromDictionary }}</span>
        <fstr-icon class="icon-chevron-right ms-2" anchor="icon-chevron-right"></fstr-icon>
      </button>
    </div>
  </fstr-dynamic-form>

  <ng-container footer>
    <span class="d-inline-block">
      <span class="fw-normal">{{ prefix + '-registreren-prefix-knop' | fromDictionary }}</span>
      <a [routerLink]="['/', 'account', 'registreren']" class="link--tertiary fw-bold me-2">
        {{ prefix + '-registreren-knop-tekst' | fromDictionary }}
      </a>
      |
    </span>
    <a [routerLink]="['/', 'account', 'wachtwoord-vergeten']" class="link--tertiary fw-bold ms-2">
      {{ prefix + '-wachtwoord-vergeten-knop-tekst' | fromDictionary }}
    </a>
  </ng-container>
</app-auth-layout>
