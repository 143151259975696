// tslint:disable:max-line-length
import { createAction, props } from '@ngrx/store';
import { InitiativeStatusUpdate } from '../../models';

export const LoadStatusUpdates = createAction('[Initiatives] Load Status Updates for Initiative', props<{ id: number }>());
export const LoadStatusUpdatesSuccess = createAction(
  '[Initiatives] Load Status Updates for Initiative Success',
  props<{ StatusUpdates: InitiativeStatusUpdate[] }>()
);
export const LoadStatusUpdatesFail = createAction('[Initiatives] Load Status Updates for Initiative Fail', props<{ error: any }>());

export const CreateStatusUpdate = createAction(
  '[Initiatives] Create Initiative Status Update',
  props<{ id: number; title: string; text: string }>()
);
export const CreateStatusUpdateSuccess = createAction(
  '[Initiatives] Create Initiative Status Update Success',
  props<{ StatusUpdate: InitiativeStatusUpdate }>()
);
export const CreateStatusUpdateFail = createAction('[Initiatives] Create Initiative Status Update Fail', props<{ error: any }>());

export const DeleteStatusUpdate = createAction(
  '[Initiatives] Delete Initiative Status Update',
  props<{ id: number; StatusUpdateId: number }>()
);
export const DeleteStatusUpdateSuccess = createAction(
  '[Initiatives] Delete Initiative Status Update Success',
  props<{ StatusUpdate: InitiativeStatusUpdate }>()
);
export const DeleteStatusUpdateFail = createAction('[Initiatives] Delete Initiative Status Update Fail', props<{ error: any }>());

export const ClearStatusUpdates = createAction('[Initiatives] Clear Initiative Status Updates');
