import { createReducer, on } from '@ngrx/store';
import { Event } from '../../models';
import * as utility from '../../../utility';
import * as EventActions from '../actions/events.action';
import { PageData } from 'src/app/core/models';

export interface EventsState {
  entities: { [id: number]: Event };
  page: PageData;
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
  error?: any;
}

export const initialState: EventsState = {
  entities: {},
  page: { current: 0, pageCount: 0, resultCount: 0, size: 6, filters: { unvoted: true } },
  loaded: false,
  loading: false,
  saved: false,
  saving: false,
};

export const EventReducer = createReducer(
  initialState,
  on(EventActions.LoadEvents, EventActions.LoadMoreEvents, (state: EventsState) => {
    return {
      ...state,
      loading: true,
      mineLoaded: false,
    };
  }),
  on(EventActions.LoadEventsSuccess, EventActions.LoadMoreEventsSuccess, (state: EventsState, { events }) => {
    const entities = utility.ToEntities(events.items, 'id', state.entities);

    return {
      ...state,
      entities,
      page: events.page,
      loading: false,
      loaded: true,
      mineLoaded: true,
    };
  }),
  on(EventActions.LoadEventsFail, (state: EventsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      mineLoaded: false,
      error,
    };
  }),
  on(EventActions.LoadEvent, (state: EventsState, { id }) => {
    const entity: Event = {
      ...state.entities[id],
      loading: true,
      loaded: false,
    };

    return {
      ...state,
      entities: {
        ...state.entities,
        [id]: entity,
      },
    };
  }),
  on(EventActions.LoadEventSuccess, (state: EventsState, { event }) => {
    console.log('castvvotesucces', event);
    return {
      ...state,
      entities: {
        ...state.entities,
        [event.id]: event,
        // [event.id]: {
        //   ...event,
        //   votes: {
        //     ...state.entities[event.id].votes,
        //     mine: direction,
        //   }
        // },
      },
    };
  }),
  on(EventActions.CreateEvent, EventActions.UpdateEvent, (state: EventsState) => {
    return {
      ...state,
      saved: false,
      saving: true,
    };
  }),
  on(EventActions.CreateEventSuccess, EventActions.UpdateEventSuccess, (state: EventsState, { event }) => {
    const entities = {
      ...state.entities,
      [event.id]: event,
    };
    return {
      ...state,
      entities,
      saved: true,
      saving: false,
    };
  }),
  on(EventActions.DeleteEventSuccess, (state: EventsState, { event }) => {
    const { [event.id]: removed, ...entities } = state.entities;
    return {
      ...state,
      entities,
      saved: true,
      saving: false,
    };
  }),
  on(EventActions.ClearEvents, (state: EventsState) => {
    return {
      ...initialState,
    };
  })
);

export const getEventsEntities = (state: EventsState) => state.entities;
export const getEventsLoading = (state: EventsState) => state.loading;
export const getEventsLoaded = (state: EventsState) => state.loaded;
export const getEventSaved = (state: EventsState) => state.saved;
export const getEventSaving = (state: EventsState) => state.saving;
export const getEventsPage = (state: EventsState) => state.page;
