<!--<header class="header" [ngClass]="cssClass" (fstrInViewport)="inViewChange($event)" [once]="false" [threshold]="0.25">
  <figure class="header__crop" *ngIf="imageUrl || videoBg">
    <img *ngIf="imageUrl" [src]="imageUrl | resize: 1980" />
    <fstr-html5-video class="page-header__video" [playerVars]="playerVars" *ngIf="inviewPort && videoBg" [src]="videoBg"></fstr-html5-video>
  </figure>
  <div class="header__content text-center">
    <h1 class="header__title h1" [innerHTML]="title"></h1>
    <h2 class="header__subtitle h3" [innerHTML]="subtitle" *ngIf="subtitle"></h2>
  </div>
  <ng-content></ng-content>
</header>-->

<header class="header">
  <div class="container">
    <main class="row section-inset-y">
      <div class="col-md-5 mb-3 mb-md-0" *ngIf="header?.image?.url">
        <app-shape-image
          [shape]="'icon-u'"
          [url]="(header?.image)!.url || '' | resize : 1200 : 1200 : header?.image?.anchor || 'middlecenter'"
          [anchor]="header?.image?.anchor || 'middlecenter'"
        ></app-shape-image>
      </div>

      <div class="col-md-6 ms-md-4 ms-lg-6 header__content">
        <div class="header__content__inner">
          <img *ngIf="showLogo" class="header__logo" [src]="'header-landingspagina-logo' | fromDictionary" alt="Blauwhaus Logo" />
          <h1 class="text-light mb-5 mt-md-0" *ngIf="title || header?.title">{{ title || header?.title }}</h1>

          <div class="header__content__actions">
            <ng-container *ngIf="!isSignedIn">
              <fstr-link
                *ngFor="let button of header?.buttons; let i = index"
                [button]="button"
                [cssClasses]="i % 2 === 0 ? 'button button--primary' : 'button button--secondary'"
              ></fstr-link>
            </ng-container>
            <a [routerLink]="['/']" class="button button--primary" *ngIf="isSignedIn">
              {{ 'header-dashboard-knop-tekst' | fromDictionary }}
            </a>
          </div>
        </div>
      </div>
    </main>
  </div>
</header>
