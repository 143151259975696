import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Go } from 'src/app/store';
import { NotificationBundle } from '../../models';
import { MarkAsRead } from '../../store';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCardComponent {
  @Input() notification!: NotificationBundle;

  constructor(private store: Store) {}

  get type() {
    const type = this.notification?.routerLink[1];

    switch (type) {
      case 'meningen':
        return 'Mening';

      case 'initiatieven':
        return 'Initiatief';

      default:
        return '';
    }
  }

  ngOnInit() {}

  read(notification: NotificationBundle) {
    if (!notification.isRead) {
      this.store.dispatch(MarkAsRead({ notification }));
    }
    this.store.dispatch(Go({ path: notification.routerLink }));
  }
}
