import * as fromEvent from './events.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'Events';

export interface EventState {
  events: fromEvent.EventsState;
}

export const reducers: ActionReducerMap<EventState> = {
  events: fromEvent.EventReducer,
};

export const getEventsState = createFeatureSelector<EventState>(featureKey);
export const metaReducers: MetaReducer<EventState>[] = !environment.production ? [] : [];
