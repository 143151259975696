import { createAction, props } from '@ngrx/store';
import { NotificationBundle } from '../../models';

export const LoadNotifications = createAction('[Notifications] Load Notifications');
export const LoadNotificationsSuccess = createAction(
  '[Notifications] Load Notifications Success',
  props<{ notifications: NotificationBundle[] }>()
);
export const LoadNotificationsFail = createAction('[Notifications] Load Notifications Fail', props<{ error: any }>());

export const MarkAsRead = createAction('[Notifications] Mark as read', props<{ notification: NotificationBundle }>());
export const MarkAsReadSuccess = createAction('[Notifications] Mark as read Success', props<{ notifications: NotificationBundle[] }>());
export const MarkAsReadFail = createAction('[Notifications] Mark as read Fail', props<{ error: any }>());
