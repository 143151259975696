<div class="app" [ngClass]="theme$ | async">
  <div class="app__inner" [class.anim--in]="loading$ | async">
    <app-topbar
      (toggleOverlay)="toggleMenu()"
      (closeOverlay)="closeMenu()"
      (openOverlay)="openMenu()"
      [loggedIn]="usr$ | async"
      [notifications]="notifications$ | async"
      [menu]="
        (usr$ | async)
          ? ('menu-topbar' | fromMenu)?.error
            ? []
            : ('menu-topbar' | fromMenu)
          : ('menu-account' | fromMenu)?.error
          ? []
          : ('menu-account' | fromMenu)
      "
      [menuActive]="(menuOpen$ | async) ?? false"
      *ngIf="loaded$ | async"
    ></app-topbar>

    <div class="app__router">
      <div class="app__router__inner" [@routerFade]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>

    <app-footer
      [menu]="('menu-footer' | fromMenu)?.error ? [] : ('menu-footer' | fromMenu)"
      [domain]="domainName"
      *ngIf="loaded$ | async"
    ></app-footer>

    <app-tab-bar
      *ngIf="(loaded$ | async) && (usr$ | async)"
      [actionTabUrl]="tabActionOpinion ? '/meningen/nieuw' : '/initiatieven/nieuw'"
      [actionTabLabel]="
        tabActionOpinion ? ('tabbar-actiontab-mening-label' | fromDictionary) : ('tabbar-actiontab-initiatief-label' | fromDictionary)
      "
    ></app-tab-bar>
  </div>
  <app-loader-indicator [loading]="(loading$ | async) ?? false"></app-loader-indicator>
  <fstr-cookie-notice
    class="theme-default"
    [title]="'cookie-titel' | fromDictionary"
    *ngIf="loaded$ | async"
    [text]="'cookie-tekst' | fromDictionary"
    [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
    [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
  ></fstr-cookie-notice>
</div>

<app-grid-overlay *ngIf="testGridEnabled"></app-grid-overlay>
