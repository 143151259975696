import { Component, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, withLatestFrom, map } from 'rxjs/operators';

import * as fromStore from '../store';
import * as fromRoot from '../../store';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OpinionsAllOverviewGuard  {
  constructor(private store: Store<fromStore.OpinionState>) {}

  canDeactivate(
    component: Component,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot | undefined
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkDeactivate().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkDeactivate(): Observable<boolean> {
    return this.store.select(fromStore.getOpinionsLoaded).pipe(
      tap(loaded => {
        if (loaded) {
          this.store.dispatch(fromStore.ClearOpinions());
        }
      }),
      filter(loaded => !loaded),
      map(loaded => !loaded),
      take(1)
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromStore.getOpinionsLoaded).pipe(
      withLatestFrom(this.store.select(fromStore.getOpinionsPage), this.store.select(fromRoot.getRouterState)),
      tap(([loaded, page, router]) => {
        if (!loaded || (+router.state.queryParams['page'] || 0) !== page.current) {
          const current = +router.state.queryParams['page'] || 0;
          this.store.dispatch(fromStore.LoadOpinions({ skip: current * page.size, take: page.size, unvoted: false }));
        }
      }),
      filter(([loaded]) => loaded),
      map(([loaded]) => loaded),
      take(1)
    );
  }
}
