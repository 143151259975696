import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/opinions.action';
import { switchMap, map, catchError, tap, withLatestFrom, filter } from 'rxjs/operators';
import { OpinionsService } from '../../services';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Go, getRouterState } from '../../../store';
import { Store } from '@ngrx/store';
import { getOpinionsPage } from '../selectors';
import { getAuthSignedIn } from 'src/app/auth/store';

@Injectable()
export class OpinionsEffects {
  constructor(
    private actions$: Actions,
    private service: OpinionsService,
    private router: Router,
    private sb: MatSnackBar,
    private store: Store
  ) {}

  LoadOpinion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadOpinion),
      switchMap(({ id }) =>
        this.service.getOpinion(id).pipe(
          map(opinion => actions.LoadOpinionSuccess({ opinion })),
          catchError(error => of(actions.LoadOpinionFail({ error })))
        )
      )
    );
  });

  LoadOpinions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadOpinions),
      switchMap(({ skip, take, category, unvoted, personalized }) =>
        this.service.getOpinions(skip, take, category, unvoted, personalized).pipe(
          map(opinions => actions.LoadOpinionsSuccess({ opinions })),
          catchError(error => of(actions.LoadOpinionsFail({ error })))
        )
      )
    );
  });

  LoadMoreOpinions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadMoreOpinions),
      withLatestFrom(this.store.select(getOpinionsPage)),
      switchMap(([{}, pagedata]) =>
        this.service
          .getOpinions(
            (pagedata.current + 1) * pagedata.size,
            pagedata.size,
            undefined,
            pagedata.filters?.['unvoted'],
            pagedata.filters?.['personalized']
          )
          .pipe(
            map(opinions => actions.LoadMoreOpinionsSuccess({ opinions })),
            catchError(error => of(actions.LoadOpinionsFail({ error })))
          )
      )
    );
  });

  CreateOpinion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CreateOpinion),
      switchMap(({ opinionData }) =>
        this.service.createOpinion(opinionData).pipe(
          map(opinion => actions.CreateOpinionSuccess({ opinion })),
          catchError(error => of(actions.CreateOpinionFail({ error })))
        )
      )
    );
  });

  UpdateOpinion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.UpdateOpinion),
      switchMap(({ id, opinionData }) =>
        this.service.updateOpinion(id, opinionData).pipe(
          map(opinion => actions.UpdateOpinionSuccess({ opinion })),
          catchError(error => of(actions.UpdateOpinionFail({ error })))
        )
      )
    );
  });

  DeleteOpinion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.DeleteOpinion),
      switchMap(({ id }) =>
        this.service.deleteOpinion(id).pipe(
          map(opinion => actions.DeleteOpinionSuccess({ opinion })),
          catchError(error => of(actions.DeleteOpinionFail({ error })))
        )
      )
    );
  });

  CloseOpinion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CloseOpinion),
      switchMap(({ id }) =>
        this.service.closeOpinion(id).pipe(
          map(opinion => actions.CloseOpinionSuccess({ opinion })),
          catchError(error => of(actions.CloseOpinionFail({ error })))
        )
      )
    );
  });

  CastVote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CastVote),
      withLatestFrom(this.store.select(getAuthSignedIn)),
      filter(([{}, signedIn]) => signedIn),
      switchMap(([{ id, direction }, signedIn]) =>
        this.service.castVote(id, direction).pipe(
          map(opinion => actions.CastVoteSuccess({ opinion })),
          catchError(error => of(actions.CastVoteFail({ error })))
        )
      )
    );
  });

  CastVoteUnauthorized$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CastVote),
      withLatestFrom(this.store.select(getAuthSignedIn), this.store.select(getRouterState)),
      filter(([{}, signedIn]) => !signedIn),
      map(([{}, signedIn, router]) => Go({ path: ['/account', 'inloggen'], queryParams: { returnUrl: router.state.url } }))
    );
  });

  updateOpinionSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.UpdateOpinionSuccess),
      tap(({ opinion }) => {
        this.sb.open('Mening geüpdatet!', '');
      }),
      map(({ opinion }) => Go({ path: opinion.url }))
    );
  });

  createOpinionSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CreateOpinionSuccess),
      tap(({ opinion }) => {
        this.sb.open('Mening aangemaakt!', '');
      }),
      map(({ opinion }) => Go({ path: opinion.url }))
    );
  });

  deleteOpinionSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.DeleteOpinionSuccess),
      tap(({ opinion }) => {
        this.sb.open('Mening verwijderd!', '');
      }),
      map(({ opinion }) => Go({ path: ['/', 'meningen'] }))
    );
  });

  closeOpinionSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CloseOpinionSuccess),
      tap(({ opinion }) => {
        this.sb.open('Mening gesloten!', '');
      }),
      map(({ opinion }) => Go({ path: opinion.url }))
    );
  });

  castVoteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.CastVoteSuccess),
        tap(({ opinion }) => {
          this.sb.open('Jouw stem is uitgebracht!', '');
        })
      );
    },
    { dispatch: false }
  );
}
