import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { DynamicFormField, SelectOption } from '@teamfoster/sdk/dynamic-form';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-form-checkbox-buttons',
  templateUrl: './form-checkbox-buttons.component.html',
})
export class FormCheckboxButtonsComponent implements OnInit, OnDestroy {
  config!: DynamicFormField;
  group!: FormGroup;

  checkBoxes = this.fb.group({});

  private _unsubscribe = new Subject<void>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.config.selectOptions?.forEach((option: SelectOption) => {
      const initialValue = this.config.value && this.config.value.some((b: any) => b.id === option.id);
      this.checkBoxes.addControl(`${this.config.name}_option_${option.id}`, this.fb.control(initialValue));
    });

    // Update checkboxgroup value to array of selected checkboxes
    this.checkBoxes.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe(val => {
      let selectedAsArray = Object.keys(val).filter(k => (<any>val)[k]);
      selectedAsArray = selectedAsArray.length > 0 ? selectedAsArray : [];

      const objectArray = this.config.selectOptions?.filter(a => selectedAsArray?.indexOf(`${this.config.name}_option_${a.id}`) >= 0) || [];

      this.group.get(this.config.name)?.patchValue(objectArray);
    });

    // If value changes outside component (patchvalue) set checkboxvalues
    this.group
      .get(this.config.name)
      ?.valueChanges.pipe(takeUntil(this._unsubscribe))
      .subscribe(values => {
        if (!values?.length) {
          return;
        }
        values.forEach((name: any) => {
          const ctrl = (<any>this.checkBoxes.controls)[`${this.config.name}_option_${name}`];
          if (ctrl) {
            ctrl.setValue(true);
          }
        });
      });
  }

  isRequired(ctrl: AbstractControl | null) {
    if (!ctrl || !ctrl.validator) {
      return false;
    }
    const validator = ctrl?.validator({} as AbstractControl);
    return validator && validator['required'];
  }

  showError(field: AbstractControl) {
    return field.invalid && (this.checkBoxes.touched || this.checkBoxes.dirty);
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
