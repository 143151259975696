import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InitiativeStatusUpdate } from '../models';

@Injectable({
  providedIn: 'root',
})
export class InitiativeStatusUpdatesService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getInitiativeStatusUpdates(id: number) {
    return this.http.get<InitiativeStatusUpdate[]>(`${this.BASE_URL}api/initiatives/${id}/updates`);
  }

  deleteInitiativeStatusUpdate(id: number, updateId: number) {
    return this.http.delete<InitiativeStatusUpdate>(`${this.BASE_URL}api/initiatives/${id}/updates/${updateId}`);
  }

  createInitiativeStatusUpdate(id: number, title: string, text: string) {
    return this.http.post<InitiativeStatusUpdate>(`${this.BASE_URL}api/initiatives/${id}/updates`, { title, text });
  }
}
