import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './containers/sign-in/sign-in.component';
import { AtuhRoutingModule } from './auth-routing.module';
import { RegisterComponent } from './containers/register/register.component';
import { ForgotPasswordComponent } from './containers/forgot-password/forgot-password.component';
import { CoreModule } from '../core/core.module';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { StoreModule } from '@ngrx/store';
import { IconModule } from '@teamfoster/sdk/icon';
import { EffectsModule } from '@ngrx/effects';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

import * as fromAuth from './store';
import { ActivateAccountComponent } from './containers/activate-account/activate-account.component';
import { RouterModule } from '@angular/router';
import { LegacyDynamicFormModule as DynamicFormModule } from '@teamfoster/sdk/legacy-dynamic-form';
import { AuthLayoutComponent } from './compontents/auth-layout/auth-layout.component';

@NgModule({
  declarations: [
    SignInComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ActivateAccountComponent,
    AuthLayoutComponent,
  ],
  imports: [
    AtuhRoutingModule,
    CommonModule,
    DynamicFormModule,
    CoreModule,
    TextUtilityModule,
    DictionaryNgrxModule,
    RouterModule,
    IconModule,
    MatSnackBarModule,
    StoreModule.forFeature(fromAuth.featureKey, fromAuth.reducers, {
      metaReducers: fromAuth.metaReducers,
    }),
    EffectsModule.forFeature(fromAuth.effects),
  ],
})
export class AuthModule {}
