// tslint:disable:max-line-length
import { createAction, props } from '@ngrx/store';
import { PagedOverview } from 'src/app/core/models';
import { Event, UpdateEventData } from '../../models';

export const LoadEvent = createAction('[Events] Load Event', props<{ id: number }>());
export const LoadEventSuccess = createAction('[Events] Load Event Success', props<{ event: Event }>());
export const LoadEventFail = createAction('[Events] Load Event Fail', props<{ error: any }>());

export const LoadEvents = createAction('[Events] Load Events', props<{ take?: number; skip?: number; category?: number }>());
export const LoadEventsSuccess = createAction('[Events] Load Events Success', props<{ events: PagedOverview<Event> }>());
export const LoadEventsFail = createAction('[Events] Load Events Fail', props<{ error: any }>());

export const LoadMoreEvents = createAction('[Events] Load MORE Events');
export const LoadMoreEventsSuccess = createAction('[Events] Load MORE Events Success', props<{ events: PagedOverview<Event> }>());

export const UpdateEvent = createAction('[Events] Update Event', props<{ id: number; eventData: UpdateEventData }>());
export const UpdateEventSuccess = createAction('[Events] Update Event Success', props<{ event: Event }>());
export const UpdateEventFail = createAction('[Events] Update Event Fail', props<{ error: any }>());

export const CreateEvent = createAction('[Events] Create Event', props<{ eventData: UpdateEventData }>());
export const CreateEventSuccess = createAction('[Events] Create Event Success', props<{ event: Event }>());
export const CreateEventFail = createAction('[Events] Create Event Fail', props<{ error: any }>());

export const DeleteEvent = createAction('[Events] Delete Event', props<{ id: number }>());
export const DeleteEventSuccess = createAction('[Events] Delete Event Success', props<{ event: Event }>());
export const DeleteEventFail = createAction('[Events] Delete Event Fail', props<{ error: any }>());

export const ClearEvents = createAction('[Events] Clear Events');
