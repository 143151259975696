<em class="date shadow--right" [ngClass]="'date--' + size" [ngClass]="'date--' + orientation">
  <div class="date__inner">
    <span class="date__day">{{ start | date : 'dd' }}</span>
    <span class="date__month">{{ getMonth(start) }}</span>
    <span class="date__year" *ngIf="!isCurrentYear(start)">{{ start | date : 'yyyy' }}</span>
  </div>

  <ng-container *ngIf="end && (end | date : 'dd') !== (start | date : 'dd')">
    <span class="text-primary date__divider">tot en met</span>

    <div class="date__inner">
      <span class="date__day">{{ end | date : 'dd' }}</span>
      <span class="date__month">{{ getMonth(end) }}</span>
      <span class="date__year" *ngIf="!isCurrentYear(end)">{{ end | date : 'yyyy' }}</span>
    </div>
  </ng-container>
</em>
