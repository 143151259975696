<app-auth-layout [title]="prefix + '-titel' | fromDictionary" icon="registreren">
  <ng-container form>
    <app-alert
      class="d-block mt-4 mb-4"
      *ngIf="registerError$ | async as errors"
      [status]="'type--danger'"
      [title]="prefix + 'formulier-error-titel' | fromDictionary"
    >
      <ng-container>
        <ul *ngIf="errors?.error?.errors.length > 1">
          <li class="" *ngFor="let error of errors?.error?.errors">
            {{ error.description }}
          </li>
        </ul>
        <div *ngIf="errors?.error?.errors.length < 2">
          <p *ngFor="let error of errors?.error?.errors">
            {{ error.description }}
          </p>
        </div>
      </ng-container>
    </app-alert>

    <fstr-dynamic-form
      *ngIf="(registerSuccess$ | async) === false"
      [config]="formFields"
      [appearance]="'fill'"
      (formChanges)="handleChanges($event)"
      (formSubmit)="handleSubmit($event)"
      [showSubmit]="false"
    >
      <div slot="form-actions">
        <button type="submit" class="button button--primary" [disabled]="!formValid">
          {{ prefix + '-knoptekst' | fromDictionary }}
          <fstr-icon class="icon-chevron-right ms-2" anchor="icon-chevron-right"></fstr-icon>
        </button>
      </div>
    </fstr-dynamic-form>

    <app-alert
      class="d-block mt-4"
      *ngIf="registerSuccess$ | async"
      [message]="prefix + '-formulier-succes-geslaagd-tekst-tmp' | fromDictionary"
      [status]="'type--success'"
      [title]="prefix + '-formulier-succes-geslaagd-titel' | fromDictionary"
    >
      <!-- TODO: Remove this button -->
      <button type="button" [routerLink]="['/']">Terug naar home</button>
    </app-alert>
  </ng-container>
</app-auth-layout>
