import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Html5PlayerVars } from '@teamfoster/sdk/html5-video-player';
import { Header } from '../../models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Input() imageUrl?: string;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() cssClass?: string;

  @Input() header?: Header;
  @Input() showLogo: boolean = false;
  @Input() isSignedIn: boolean = false;

  constructor() {}
}
