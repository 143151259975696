import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromNotifications from '../reducers/notifications.reducer';

export const getNotificationState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.notifications);
export const getAllNotifications = createSelector(getNotificationState, fromNotifications.getNotificationsEntities);
export const getAllUnreadedNotifications = createSelector(getAllNotifications, notifications => {
  return notifications.filter(n => !n.isRead);
});
export const getNotificationsLoaded = createSelector(getNotificationState, fromNotifications.getNotificationsLoaded);
export const getNotificationsLoading = createSelector(getNotificationState, fromNotifications.getNotificationsLoading);
