import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quillRemoveLinebreak',
})
export class QuillRemoveLinebreakPipe implements PipeTransform {
  transform(value: string): any {
    return value.replace('<p><br></p>', '');
  }
}
