import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationBundle } from '../models';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getNotifications() {
    return this.http.get<NotificationBundle[]>(`${this.BASE_URL}api/notifications`);
  }

  markAsRead(notification: NotificationBundle) {
    return this.http.post<NotificationBundle[]>(`${this.BASE_URL}api/notifications/${notification.subscriptionId}/read`, null);
  }
}
