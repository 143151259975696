import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromEvent from '../reducers/events.reducer';
import * as fromRoot from '../../../store';
import * as fromAuth from '../../../auth/store';
import { Event } from '../../models';

export const getEventState = createSelector(fromFeature.getEventsState, (state: fromFeature.EventState) => state.events);

export const getEventsEntities = createSelector(getEventState, fromEvent.getEventsEntities);

export const getAllEvents = createSelector(getEventsEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

export const getMyEvents = createSelector(getAllEvents, fromAuth.getAuthUser, (events, usr) => {
  return events?.filter(a => a.createdById === usr?.id);
});

export const getEventsLoaded = createSelector(getEventState, fromEvent.getEventsLoaded);
export const getEventsLoading = createSelector(getEventState, fromEvent.getEventsLoading);
export const getEventSaved = createSelector(getEventState, fromEvent.getEventSaved);
export const getEventSaving = createSelector(getEventState, fromEvent.getEventSaving);
export const getEventsPage = createSelector(getEventState, fromEvent.getEventsPage);

export const getOrderedEvents = createSelector(getAllEvents, getEventsPage, (Pages, page) => {
  let orderedPages = Pages.sort((a, b) => (new Date(a.startDate) > new Date(b.startDate) ? 1 : -1));
  return orderedPages;
});

export const getSelectedEvent = createSelector(getEventsEntities, fromRoot.getRouterState, (entities, router): Event => {
  return router.state && entities[router.state.params?.['eventId'] || router.state.data?.['id']];
});

export const getSelectedEventLoaded = createSelector(getSelectedEvent, (c: Event) => {
  return c?.loaded;
});

export const getSelectedEventLoading = createSelector(getSelectedEvent, (c: Event) => {
  return c?.loading;
});
