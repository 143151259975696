import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
  HostBinding,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ResizePipe, Image } from '@teamfoster/sdk/image';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ImageComponent implements OnInit {
  @Input() image!: Image; // instead of url you can also use the new Image model
  @Input() objectFitMode: boolean = false; //Uses CSS object-fit modus instead of url
  @Output() loaded = new EventEmitter<boolean>();

  @Input() debug: boolean = false; // Shows debugdata in table
  @Input() alt!: string;
  //@Input() src!: string; // URL without resizing pipes etc.
  //@Input() alt!: string; // Regular Alt text

  @Input() loading = 'lazy'; // Lazy loading

  // When both width and height are filled, this is used to calculate the aspect-ratio
  @Input() width: number = 0;
  @Input() height: number = 0;

  // if image get's clipped, this determines from what anchor
  //@Input() anchor:
  //  | 'topleft'
  //  | 'topcenter'
  //  | 'topright'
  //  | 'middleleft'
  //  | 'middlecenter'
  //  | 'middleright'
  //  | 'bottomleft'
  //  | 'bottomcenter'
  //  | 'bottomright' = 'middlecenter';

  // Type of clipping when aspect ratio is given.
  @Input() mode: 'crop' | 'stretch' | 'pad' | 'max' = 'crop';

  //// Image file format
  @Input() format: 'jpg' | 'png' | 'bmp' | 'gif' | 'webp' = 'webp';

  //// Scale
  @Input() scale: 'both' | 'down' | 'canvas' = 'both';

  // Set what image sizes the srcset will contain (this will be used to pick from)
  @Input() imageSizes: number[] = [550, 800, 1200, 1600, 1920];

  // How many cols does the image span on what breakpoint?
  // For example: { md: 4, xl: 3 } is the same as bootstrap .col-md-4 .col-xl-3 classes
  @Input() sizes: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
  } = {};

  // Overwrite the sizes attribute (to set it manually)
  @Input() customSizes: string = '';

  // Used only for debugging purposes
  @ViewChild('img') img: ElementRef<HTMLImageElement> | undefined;

  // Inherited from CSS: $grid-breakpoints
  // is used to generate size attribute with collspans
  cssBreakPoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  };

  gcd: number = 0; // GCD is the highest number that evenly divides both numbers
  aspectRatio: string = 'auto'; // used for CSS, outputs an string as such: '16/9' or 'auto'
  currentSrc: string | undefined; // on resize this will be updated with the chosen image url

  private resize = new ResizePipe();
  private dict = new FromDictionaryPipe(this.lang);

  constructor(private cd: ChangeDetectorRef, private sanitizer: DomSanitizer, private lang: LanguageService) {}

  ngOnInit(): void {
    this.gcd = this.aspect(this.height, this.width);
    this.aspectRatio = this.width && this.height ? `${this.width / this.gcd} / ${this.height / this.gcd}` : 'auto';

    // required alt text
    if (!this.image?.alt && !this.alt) {
      console.error(`Attribute 'alt' is required for image`, this.image);
    }
  }

  //? enable this if you want to test with chosen img size
  @HostBinding('attr.style')
  public get getCSSvariables(): any {
    return this.sanitizer.bypassSecurityTrustStyle('--aspect-ratio:' + this.aspectRatio);
  }

  // Test purposes only
  // shows which src gets chosen
  // @HostListener('window:resize', ['$event'])
  // onResize() {
  //   this.currentSrc = this.img.nativeElement.currentSrc.replace(this.src, '');
  //   this.cd.markForCheck();
  // }

  get sizesAttr() {
    // So youll be able to make your own size property build
    if (this.customSizes) {
      return this.customSizes;
    }
    if (!this.sizes) {
      return 'auto';
    }

    const sizeRules: string[] = [];

    // Sets for each container width an srcset (so for full width images)
    Object.keys(this.sizes).forEach(key => {
      const size = this.sizes[key as keyof typeof this.sizes];
      const breakpoint = this.cssBreakPoints[key as keyof typeof this.cssBreakPoints];

      if (!size || !breakpoint) {
        return;
      }

      const width = (100 / 12) * size;

      sizeRules.push(`(min-width: ${breakpoint}px) ${width}vw`); // TODO calculate width with max width of container & vw?
    });

    return sizeRules.join(',').concat(', 100vw');
  }

  get srcSetAttr() {
    const srcSetRules: string[] = [];

    this.imageSizes.forEach(imgWidth => {
      srcSetRules.push(
        `${this.resize.transform(
          encodeURI(this.image.url || this.dict.transform('placeholder-image')),
          imgWidth,
          this.getAspectRatioHeight(imgWidth),
          this.image.anchor || undefined,
          this.mode,
          this.format,
          this.scale
        )}  ${imgWidth}w` // TODO calculate width with max width of container & vw?
      );
    });

    return srcSetRules.join(',');
  }

  getAspectRatioHeight(size: number): number {
    if (!this.height || !this.width) {
      return 0;
    }

    // ? What if only height is given ?
    const percentageDiff = (size - this.width) / this.width;
    const newHeight = Math.round((percentageDiff + 1) * this.height);

    return newHeight;
  }

  aspect(h: number, w: number): number {
    return !w ? h : this.aspect(w, h % w);
  }
}
