import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/comments.action';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { OpinionCommentsService } from '../../services';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Go } from '../../../store';

@Injectable()
export class OpinionCommentsEffects {
  constructor(private actions$: Actions, private service: OpinionCommentsService, private router: Router, private sb: MatSnackBar) {}

  LoadComments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadComments),
      switchMap(({ id }) =>
        this.service.getOpinionComments(id).pipe(
          map(comments => actions.LoadCommentsSuccess({ comments })),
          catchError(error => of(actions.LoadCommentsFail({ error })))
        )
      )
    );
  });

  CreateComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CreateComment),
      switchMap(({ id, comment }) =>
        this.service.createOpinionComment(id, comment).pipe(
          map(comment => actions.CreateCommentSuccess({ comment })),
          catchError(error => of(actions.CreateCommentFail({ error })))
        )
      )
    );
  });

  DeleteComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.DeleteComment),
      switchMap(({ id, commentId }) =>
        this.service.deleteOpinionComment(id, commentId).pipe(
          map(comment => actions.DeleteCommentSuccess({ comment })),
          catchError(error => of(actions.DeleteCommentFail({ error })))
        )
      )
    );
  });

  createCommentSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.CreateCommentSuccess),
        tap(({}) => {
          this.sb.open('Reactie aangemaakt!', '');
        })
      );
    },
    { dispatch: false }
  );

  deleteOpinionCommentSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.DeleteCommentSuccess),
        tap(({}) => {
          this.sb.open('Reactie verwijderd!', '');
        })
      );
    },
    { dispatch: false }
  );
}
