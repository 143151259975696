<ng-container *ngIf="group.get(config.name) as field">
  <div
    class="custom-field custom-field--has-label type--file-upload"
    [class.has--label]="!!config.label"
    [formGroup]="group"
    [ngClass]="{
      'custom-field--invalid': showError(field)
    }"
  >
    <label class="label">
      <span [innerHTML]="config.label"></span>
      <span *ngIf="isRequired(field)">*</span>
    </label>
    <div class="custom-field-subLabel" *ngIf="config.subLabel" [innerHTML]="config.subLabel"></div>

    <input type="text" [formControlName]="config.name" hidden />

    <!-- File field  -->
    <input type="file" (change)="onFileSelected($event)" #fileUpload hidden [accept]="config['accept'] ? config['accept'] : '*'" />

    <div
      class="file-upload custom-field__field"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      [class.is--drag-over]="dragOver"
      [class.is--not-empty]="!!file"
      #fileUpload
    >
      <div class="file-upload__empty-state" *ngIf="!filePreview">
        <strong>
          {{ 'bestand-uploaden-drop-tekst' | fromDictionary }}
          <a (click)="fileUpload.click()">kies bestand</a>
        </strong>
        <br />
        <span class="type-body-xs text-muted" *ngIf="config['acceptHint']">{{ config['acceptHint'] }}</span>
      </div>

      <div class="file-upload__file" *ngIf="filePreview">
        <figure class="file-upload__file__image mb-2 mb-md-0 me-md-3" *ngIf="filePreview">
          <img *ngIf="!isPreview" [src]="config.value + '?width=500'" [alt]="fileName" class="d-block" />
          <img *ngIf="isPreview" [src]="filePreview" [alt]="fileName" class="d-block" />
        </figure>
        <div class="file-upload__file__meta">
          <span>{{ 'bestand-uploaden-geselecteerde-label' | fromDictionary }}</span>
          <br />

          <div class="d-flex">
            <strong class="text-truncate d-block">{{ fileName || filePreview }}</strong>
          </div>

          <div class="d-flex flex-wrap mt-2 file-upload__file__actions">
            <a class="button button-link button--primary me-3" (click)="fileUpload.click()">
              <fstr-icon anchor="icon-replace"></fstr-icon>
              <span>Vervangen</span>
            </a>
            <a class="button button-link button--primary" (click)="clearFile()">
              <fstr-icon anchor="icon-trash"></fstr-icon>
              <span>Verwijderen</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="custom-field__error-wrapper">
      <div class="mat-error" *ngIf="showError(field)">
        {{ error(field) }}
      </div>
    </div>
  </div>
</ng-container>
