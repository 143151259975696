import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
})
export class ConfirmComponent implements OnInit {
  @Input() message!: string;
  @Input() title!: string;
  @Input() cssClass!: string;

  constructor(public dialogRef: MatDialogRef<ConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.message = this.data.message;
    this.title = this.data.title;
    this.cssClass = this.data.cssClass;

    this.dialogRef.addPanelClass('confirm-dialog');
  }

  confirmHandler(e: any) {
    if (e) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(false);
    }
  }
}
