import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { HeaderComponent } from './components/header/header.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { IconModule } from '@teamfoster/sdk/icon';
import { Html5VideoPlayerModule } from '@teamfoster/sdk/html5-video-player';
import { GridOverlayComponent } from './components/grid-overlay/grid-overlay.component';
import { ImageComponent } from './components/image/image.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { SeoModule } from '../seo/seo.module';
import { AlertComponent } from './components/alert/alert.component';
import { FormFileInputComponent } from './form-fields/form-file-input/form-file-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormCheckboxButtonsComponent } from './form-fields/form-checkbox-buttons/form-checkbox-buttons.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CreatedByComponent } from './components/created-by/created-by.component';
import { QuillRemoveLinebreakPipe } from './pipes/quill-linebreak.pipe';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { ImageModule } from '@teamfoster/sdk/image';
import { ButtonModule } from '@teamfoster/sdk/button';
import { ShapeImageComponent } from './components/shape-image/shape-image.component';
import { DateComponent } from './components/date/date.component';

const moduleExports = [
  LoaderIndicatorComponent,
  HeaderComponent,
  TopbarComponent,
  FooterComponent,
  GridOverlayComponent,
  AlertComponent,
  FormFileInputComponent,
  FormCheckboxButtonsComponent,
  ImageComponent,
  ConfirmComponent,
  CreatedByComponent,
  QuillRemoveLinebreakPipe,
  TabBarComponent,
  ShapeImageComponent,
  DateComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    InViewModule,
    TextUtilityModule,
    ReactiveFormsModule,
    Html5VideoPlayerModule,
    IconModule,
    DictionaryNgrxModule,
    SeoModule,
    MatDialogModule,
    ImageModule,
    ButtonModule,
  ],
  declarations: [...moduleExports],
  exports: [...moduleExports],
  providers: [],
})
export class CoreModule {}
