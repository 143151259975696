import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromContributors from '../reducers/contributors.reducer';
import * as fromRoot from '../../../store';
import * as fromAuth from '../../../auth/store';
import { InitiativeContributor } from '../../models';

export const getContributorState = createSelector(
  fromFeature.getInitiativesState,
  (state: fromFeature.InitiativeState) => state.contributors
);

export const getContributorsEntities = createSelector(getContributorState, fromContributors.getContributorsEntities);
export const getContributorsLoaded = createSelector(getContributorState, fromContributors.getContributorsLoaded);
export const getContributorsLoading = createSelector(getContributorState, fromContributors.getContributorsLoading);
export const getContributorsContributed = createSelector(getContributorState, fromContributors.getContributorsContributed);

export const getSelectedContributors = createSelector(
  getContributorsEntities,
  fromRoot.getRouterState,
  (entities, router): InitiativeContributor[] => {
    return (
      router.state &&
      entities[router.state.params?.['initiativeId'] || router.state.data?.['id']]?.sort((a, b) =>
        new Date(b.createdOn) > new Date(a.createdOn) ? 1 : -1
      )
    );
  }
);

export const getSelectedContributorLoaded = createSelector(getContributorsLoaded, fromRoot.getRouterState, (loaded, router) => {
  const id = router.state && router.state.params?.['initiativeId'];
  if (!id) {
    return false;
  }
  return loaded.indexOf(+id) >= 0;
});

export const getSelectedContributorLoading = createSelector(getContributorsLoading, fromRoot.getRouterState, (loaded, router) => {
  const id = router.state && router.state.params?.['initiativeId'];
  if (!id) {
    return false;
  }
  return loaded.indexOf(+id) >= 0;
});

export const getSelectedContributorContributed = createSelector(
  getContributorsContributed,
  fromRoot.getRouterState,
  (contributed, router) => {
    const id = router.state && router.state.params?.['initiativeId'];
    if (!id) {
      return false;
    }
    return contributed.indexOf(+id) >= 0;
  }
);
