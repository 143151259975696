<footer class="footer">
  <ul class="footer__nav-list py-3 d-flex type-body-xs">
    <li class="footer__nav-list__item">{{ year }} &copy; {{ 'meta-titel-suffix' | fromDictionary }}</li>

    <li class="footer__nav-list__item ps-2">
      <button (click)="openCookie()" class="button-link button--xs">{{ 'cookie-settings' | fromDictionary }}</button>
    </li>
    <li class="footer__nav-list__item ps-2" *ngFor="let item of menu">
      <a [routerLink]="item.routerLink">{{ item.title }}</a>
    </li>
  </ul>
  <app-json-ld [json]="schema"></app-json-ld>
</footer>
