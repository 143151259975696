import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromComments from '../reducers/comments.reducer';
import * as fromInitiatives from '../selectors/initiatives.selector';
import { Initiative } from '../../models';

export const GetCommentstate = createSelector(fromFeature.getInitiativesState, (state: fromFeature.InitiativeState) => state.comments);

export const GetCommentsEntities = createSelector(GetCommentstate, fromComments.getCommentsEntities);

export const getAllComments = createSelector(GetCommentsEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

export const getOrderedComments = createSelector(getAllComments, comments => {
  return comments.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
});

export const GetCommentsLoadedFor = createSelector(GetCommentstate, fromComments.getCommentsLoadedFor);
export const GetCommentsLoadingFor = createSelector(GetCommentstate, fromComments.getCommentsLoadingFor);
export const GetCommentsaved = createSelector(GetCommentstate, fromComments.getCommentSaved);
export const GetCommentsaving = createSelector(GetCommentstate, fromComments.getCommentSaving);

export const getSelectedInitiativeCommentsLoaded = createSelector(
  GetCommentsLoadedFor,
  fromInitiatives.getSelectedInitiative,
  (loadedFor: number | undefined, o: Initiative) => {
    return o?.id === loadedFor;
  }
);

export const getSelectedInitiativeCommentsLoading = createSelector(
  GetCommentsLoadingFor,
  fromInitiatives.getSelectedInitiative,
  (loadingFor: number | undefined, o: Initiative) => {
    return o?.id === loadingFor;
  }
);
