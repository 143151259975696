<ng-container [formGroup]="group">
  <div
    class="custom-field my-3"
    *ngIf="group.get(config.name) as field"
    [class.has--label]="!!config.label"
    [ngClass]="{
      'custom-field--invalid': showError(field)
    }"
  >
    <input [formControlName]="config.name" [hidden]="true" />

    <div class="mat-label label">
      {{ config.label }}
      <span class="mat-form-field-required-marker" *ngIf="isRequired(group.get(config.name))">*</span>
    </div>
    <div class="mat-hint mb-3" *ngIf="config.hint">{{ config.hint }}</div>

    <ul [formGroup]="checkBoxes" class="d-flex flex-wrap">
      <li class="mb-1" *ngFor="let option of config.selectOptions; let i = index">
        <label class="button-checkbox-wrapper pe-4">
          <input type="checkbox" appearance="standard" [value]="option" [formControlName]="config.name + '_option_' + option.id" />
          <button
            type="button"
            [ngClass]="config['buttonSize'] ? config['buttonSize'] : 'button--sm'"
            class="button-checkbox button button-slanted button--primary"
          >
            <span>{{ option.title }}</span>
          </button>
        </label>
      </li>
    </ul>
  </div>
</ng-container>
