import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InitiativeComment } from '../models';

@Injectable({
  providedIn: 'root',
})
export class InitiativeCommentsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getInitiativeComments(id: number) {
    return this.http.get<InitiativeComment[]>(`${this.BASE_URL}api/initiatives/${id}/comments`);
  }

  deleteInitiativeComment(id: number, commentId: number) {
    return this.http.delete<InitiativeComment>(`${this.BASE_URL}api/initiatives/${id}/comments/${commentId}`);
  }

  createInitiativeComment(id: number, comment: string) {
    return this.http.post<InitiativeComment>(`${this.BASE_URL}api/initiatives/${id}/comments`, { text: comment });
  }
}
