import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Initiative, UpdateInitiativeData } from '../models/initiative.model';
import { PagedOverview } from 'src/app/core/models';
import { InitiativeContributor, VoteDirection } from '../models';

@Injectable({
  providedIn: 'root',
})
export class InitiativesService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getInitiatives(skip: number = 0, take: number = 0, category: number = 0, unvoted: boolean = false, personalized: boolean = false) {
    return this.http.get<PagedOverview<Initiative>>(
      `${this.BASE_URL}api/initiatives?take=${take}&skip=${skip}&category=${category}&unvoted=${unvoted}&personalized=${personalized}`
    );
  }

  getInitiative(id: number) {
    return this.http.get<Initiative>(`${this.BASE_URL}api/initiatives/${id}`);
  }

  updateInitiative(id: number, data: UpdateInitiativeData) {
    const formData: FormData = new FormData();
    if (data.image instanceof Blob) {
      formData.append('image', data.image);
    } else {
      formData.append('image', <Blob>(null as unknown));
    }
    formData.append('title', data.title);
    formData.append('text', data.text);
    formData.append('helpText', data.helpText);
    data.categories.forEach((category, i) => {
      formData.append(`categories[${i}].id`, category.id.toString());
      formData.append(`categories[${i}].title`, category.title);
    });
    formData.append('id', data.id?.toString());
    return this.http.put<Initiative>(`${this.BASE_URL}api/initiatives/${id}`, formData);
  }

  deleteInitiative(id: number) {
    return this.http.delete<Initiative>(`${this.BASE_URL}api/initiatives/${id}`);
  }

  closeInitiative(id: number) {
    return this.http.post<Initiative>(`${this.BASE_URL}api/initiatives/${id}/close`, null);
  }

  castVote(id: number, direction: VoteDirection) {
    return this.http.post<Initiative>(`${this.BASE_URL}api/initiatives/${id}/vote`, direction);
  }

  createInitiative(data: UpdateInitiativeData) {
    const formData: FormData = new FormData();
    if (data.image instanceof Blob) {
      formData.append('image', data.image);
    } else {
      formData.append('image', <Blob>(null as unknown));
    }
    formData.append('title', data.title);
    formData.append('text', data.text);
    data.categories.forEach((category, i) => {
      formData.append(`categories[${i}].id`, category.id.toString());
      formData.append(`categories[${i}].title`, category.title);
    });
    formData.append('id', data.id?.toString());
    return this.http.post<Initiative>(`${this.BASE_URL}api/initiatives`, formData);
  }

  createInitiativeFromOpinion(OpinionId: number) {
    return this.http.post<Initiative>(`${this.BASE_URL}api/opinions/${OpinionId}/to-initiative`, null);
  }

  getInitiativeContributors(initiativeId: number) {
    return this.http.get<InitiativeContributor[]>(`${this.BASE_URL}api/initiatives/${initiativeId}/contributors`);
  }

  MakeInitiativeContribution(initiativeId: number, motivation: { message: string }) {
    return this.http.post<InitiativeContributor>(`${this.BASE_URL}api/initiatives/${initiativeId}/contributors`, motivation);
  }
}
