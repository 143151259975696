import { createReducer, on } from '@ngrx/store';
import { Initiative } from '../../models';
import * as utility from '../../../utility';
import * as InitiativeActions from '../actions/initiatives.action';
import { PageData } from 'src/app/core/models';

export interface InitiativesState {
  entities: { [id: number]: Initiative };
  page: PageData;
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
  error?: any;
}

export const initialState: InitiativesState = {
  entities: {},
  page: { current: 0, pageCount: 0, resultCount: 0, size: 12, filters: { unvoted: false } },
  loaded: false,
  loading: false,
  saved: false,
  saving: false,
};

export const InitiativeReducer = createReducer(
  initialState,
  on(InitiativeActions.LoadInitiatives, InitiativeActions.LoadMoreInitiatives, (state: InitiativesState) => {
    return {
      ...state,
      loading: true,
      mineLoaded: false,
    };
  }),
  on(InitiativeActions.LoadInitiativesSuccess, InitiativeActions.LoadMoreInitiativesSuccess, (state: InitiativesState, { initiatives }) => {
    const entities = utility.ToEntities(initiatives.items, 'id', state.entities);

    return {
      ...state,
      entities,
      page: initiatives.page,
      loading: false,
      loaded: true,
      mineLoaded: true,
    };
  }),
  on(InitiativeActions.LoadInitiativesFail, (state: InitiativesState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      mineLoaded: false,
      error,
    };
  }),
  on(InitiativeActions.LoadInitiative, (state: InitiativesState, { id }) => {
    const entity: Initiative = {
      ...state.entities[id],
      loading: true,
      loaded: false,
    };

    return {
      ...state,
      entities: {
        ...state.entities,
        [id]: entity,
      },
    };
  }),
  // on(InitiativeActions.CastVote, (state: InitiativesState, { direction, id }) => {
  //   const entities = {
  //     ...state.entities,
  //     [id]: {
  //       ...state.entities[id],
  //       votes: {
  //         ...state.entities[id].votes,
  //         mine: direction,
  //       },
  //     },
  //   };
  //   return {
  //     ...state,
  //     entities,
  //   };
  // }),
  on(InitiativeActions.LoadInitiativeSuccess, InitiativeActions.CastVoteSuccess, (state: InitiativesState, { initiative }) => {
    // console.log('castvvotesucces', initiative);
    return {
      ...state,
      entities: {
        ...state.entities,
        [initiative.id]: initiative,
        // [initiative.id]: {
        //   ...initiative,
        //   votes: {
        //     ...state.entities[initiative.id].votes,
        //     mine: direction,
        //   }
        // },
      },
    };
  }),
  on(
    InitiativeActions.CreateInitiative,
    InitiativeActions.UpdateInitiative,
    InitiativeActions.CloseInitiative,
    (state: InitiativesState) => {
      return {
        ...state,
        saved: false,
        saving: true,
      };
    }
  ),
  on(
    InitiativeActions.CreateInitiativeSuccess,
    InitiativeActions.UpdateInitiativeSuccess,
    InitiativeActions.CloseInitiativeSuccess,
    (state: InitiativesState, { initiative }) => {
      const entities = {
        ...state.entities,
        [initiative.id]: initiative,
      };
      return {
        ...state,
        entities,
        saved: true,
        saving: false,
      };
    }
  ),
  on(InitiativeActions.DeleteInitiativeSuccess, (state: InitiativesState, { initiative }) => {
    const { [initiative.id]: removed, ...entities } = state.entities;
    return {
      ...state,
      entities,
      saved: true,
      saving: false,
    };
  }),
  on(InitiativeActions.ClearInitiatives, (state: InitiativesState) => {
    return {
      ...initialState,
    };
  })
);

export const getInitiativesEntities = (state: InitiativesState) => state.entities;
export const getInitiativesLoading = (state: InitiativesState) => state.loading;
export const getInitiativesLoaded = (state: InitiativesState) => state.loaded;
export const getInitiativeSaved = (state: InitiativesState) => state.saved;
export const getInitiativeSaving = (state: InitiativesState) => state.saving;
export const getInitiativesPage = (state: InitiativesState) => state.page;
