import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-shape-image',
  templateUrl: './shape-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShapeImageComponent {
  @Input() shape!: string;
  @Input() url!: string;
  @Input() anchor: string = 'middlecenter';
  @Input() backgroundColor: string = 'orange';

  get shapePath() {
    return `url('/assets/dist/svg/stack/svg/sprite.stack.svg#${this.shape}')`;
  }

  get CSSImageUrl() {
    return `url('${this.url}')`;
  }
}
