import * as fromInitiative from './initiatives.reducer';
import * as fromComments from './comments.reducer';
import * as fromContributors from './contributors.reducer';
import * as fromUpdates from './status-updates.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'Initiatives';

export interface InitiativeState {
  initiatives: fromInitiative.InitiativesState;
  comments: fromComments.CommentsState;
  contributors: fromContributors.ContributorsState;
  statusUpdates: fromUpdates.InitiativeStatusUpdatesState;
}

export const reducers: ActionReducerMap<InitiativeState> = {
  initiatives: fromInitiative.InitiativeReducer,
  comments: fromComments.CommentReducer,
  contributors: fromContributors.ContributorReducer,
  statusUpdates: fromUpdates.InitiativeStatusUpdatesReducer,
};

export const getInitiativesState = createFeatureSelector<InitiativeState>(featureKey);
export const metaReducers: MetaReducer<InitiativeState>[] = !environment.production ? [] : [];
