import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { getRegisterError, getRegistering, getRegisterSuccess, Register } from '../../store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { SetTitle } from '../../../store';
import { DynamicFormField } from '@teamfoster/sdk/dynamic-form';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
  registering$: Observable<boolean> = of(false);
  registerSuccess$: Observable<boolean> = of(false);
  registerError$: Observable<any> = of();

  prefix = 'registreren';
  formValid = false;
  private dict = new FromDictionaryPipe(this.lang);

  formFields: DynamicFormField[] = [];

  constructor(private fb: FormBuilder, private lang: LanguageService, private store: Store, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.formFields = [
      {
        inputType: 'email',
        label: this.dict.transform(`${this.prefix}-email-label`),
        name: 'email',
        validators: [Validators.email, Validators.required],
        autocomplete: 'email',
        placeholder: this.dict.transform(`${this.prefix}-email-placeholder`),
        fieldType: 'input',
        order: 0,
      },
      {
        inputType: 'text',
        label: this.dict.transform(`${this.prefix}-voornaam-label`),
        name: 'firstName',
        validators: [Validators.required],
        autocomplete: 'given-name',
        placeholder: this.dict.transform(`${this.prefix}-voornaam-placeholder`),
        fieldType: 'input',
        order: 1,
      },
      {
        inputType: 'text',
        label: this.dict.transform(`${this.prefix}-achternaam-label`),
        name: 'lastName',
        validators: [Validators.required],
        autocomplete: 'family-name',
        placeholder: this.dict.transform(`${this.prefix}-achternaam-placeholder`),
        fieldType: 'input',
        order: 1,
      },
      {
        inputType: 'password',
        label: this.dict.transform(`${this.prefix}-wachtwoord-label`),
        name: 'password',
        validators: [Validators.required],
        autocomplete: 'new-password',
        hint: this.dict.transform('registreren-wachtwoord-hint'),
        fieldType: 'input',
        placeholder: this.dict.transform(`${this.prefix}-wachtwoord-placeholder`),
        order: 1,
        cssClass: 'mb-3',
      },
      {
        inputType: 'password',
        label: this.dict.transform(`${this.prefix}-wachtwoord-bevestigen-label`),
        name: 'confirmPassword',
        validators: [Validators.required],
        autocomplete: 'new-password',
        placeholder: this.dict.transform(`${this.prefix}-wachtwoord-bevestigen-placeholder`),
        fieldType: 'input',
        order: 1,
      },

      {
        fieldType: 'checkbox',
        label: '',
        checkboxLabel: this.dict.transform(`${this.prefix}-voorwaarden-label`),
        name: 'consent',
        validators: [Validators.requiredTrue],
        order: 1,
      },
      {
        fieldType: 'checkbox',
        label: '',
        checkboxLabel: this.dict.transform(`${this.prefix}-organisatie-check`),
        name: 'isCompany',
        validators: [],
        order: 1,
      },
    ];
    this.registerSuccess$ = this.store.select(getRegisterSuccess);
    this.registering$ = this.store.select(getRegistering);
    this.registerError$ = this.store.select(getRegisterError).pipe(
      tap(error => {
        if (!error?.ok) {
          window.scrollTo(0, 0);
        }
      })
    );
    this.store.dispatch(
      SetTitle({ title: `${this.dict.transform(this.prefix + '-titel')} - ${this.dict.transform('meta-titel-suffix')}` })
    );
  }

  handleChanges(event: any) {
    const formValue = event.value;

    if (formValue.isCompany) {
      if (!event.get('acceptedManifest')) {
        const manifest: DynamicFormField[] = [
          {
            id: '32ManiFest',
            fieldType: 'checkbox',
            label: '',
            checkboxLabel: this.dict.transform(`${this.prefix}-manifest-label`),
            name: 'acceptedManifest',
            validators: [Validators.requiredTrue],
            order: 1,
            cssClass: 'manifest-checkbox',
          },
          {
            id: '32Company',
            inputType: 'text',
            label: this.dict.transform(`${this.prefix}-bedrijf-label`),
            name: 'companyName',
            validators: [Validators.required],
            placeholder: this.dict.transform(`${this.prefix}-bedrijf-label`),
            fieldType: 'input',
            order: 1,
          },
        ];

        this.formFields = [...this.formFields, ...manifest];
        event.addControl('acceptedManifest', new FormControl('', [Validators.requiredTrue]));
        event.addControl('companyName', new FormControl('', [Validators.required]));
      }
    } else {
      if (event.get('acceptedManifest')) {
        event.removeControl('acceptedManifest'); // remove manifest control
        event.removeControl('companyName'); // remove company control
        const fields = this.formFields.filter(field => field['id'] !== '32ManiFest').filter(field => field['id'] !== '32Company'); // remove from fields
        this.formFields = fields;
      }
    }

    // disable or enable submit button by valid form
    this.formValid = event.valid;
    this.cd.detectChanges();
  }

  handleSubmit(registerData: any) {
    const newPasswordIsValid = registerData?.password.value === registerData?.confirmPassword.value;
    // console.log(registerData.company, registerData.acceptedManifest);

    if (newPasswordIsValid) {
      console.log('submit');
      this.store.dispatch(Register({ registerData }));
    }
  }
}
