import { createReducer, on } from '@ngrx/store';
import { NotificationBundle } from '../../models';
import * as actions from '../actions/notifications.action';
import * as utility from '../../../utility';

export interface NotificationsState {
  entities: NotificationBundle[];
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: NotificationsState = {
  entities: [],
  loaded: false,
  loading: false,
};

export const NotificationReducer = createReducer(
  initialState,
  on(actions.LoadNotifications, actions.MarkAsRead, (state: NotificationsState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(actions.LoadNotificationsSuccess, actions.MarkAsReadSuccess, (state: NotificationsState, { notifications }) => {
    return {
      ...state,
      entities: notifications,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.LoadNotificationsFail, actions.MarkAsReadFail, (state: NotificationsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getNotificationsEntities = (state: NotificationsState) => state.entities;
export const getNotificationsLoading = (state: NotificationsState) => state.loading;
export const getNotificationsLoaded = (state: NotificationsState) => state.loaded;
