import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromNotifications from './store';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { IconModule } from '@teamfoster/sdk/icon';
import { CoreModule } from '../core/core.module';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { NotificationCardComponent } from './components/notification-card/notification-card.component';

@NgModule({
  declarations: [NotificationsListComponent, NotificationCardComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromNotifications.featureKey, fromNotifications.reducers, {
      metaReducers: fromNotifications.metaReducers,
    }),
    EffectsModule.forFeature(fromNotifications.effects),
    TextUtilityModule,
    IconModule,
    CoreModule,
    DictionaryNgrxModule,
  ],
  exports: [NotificationsListComponent, NotificationCardComponent],
})
export class NotificationsModule {}
