import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Go } from 'src/app/store';
import { NotificationBundle } from '../../models';
import { MarkAsRead } from '../../store';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsListComponent {
  @Input() notifications!: NotificationBundle[] | null;

  constructor(private store: Store) {}

  read(notification: NotificationBundle) {
    this.store.dispatch(MarkAsRead({ notification }));
    this.store.dispatch(Go({ path: notification.routerLink }));
  }
}
