import { createReducer, on } from '@ngrx/store';
import * as utility from '../../../utility';
import * as actions from '../actions/status-updates.action';
import { PageData } from 'src/app/core/models';
import { InitiativeStatusUpdate } from '../../models';

export interface InitiativeStatusUpdatesState {
  entities: { [id: number]: InitiativeStatusUpdate };
  loadingFor?: number;
  loadedFor?: number;
  saving: boolean;
  saved: boolean;
  error?: any;
}

export const initialState: InitiativeStatusUpdatesState = {
  entities: {},
  loadedFor: undefined,
  loadingFor: undefined,
  saved: false,
  saving: false,
};

export const InitiativeStatusUpdatesReducer = createReducer(
  initialState,
  on(actions.LoadStatusUpdates, (state: InitiativeStatusUpdatesState, { id }) => {
    return {
      ...state,
      loadingFor: +id,
    };
  }),
  on(actions.LoadStatusUpdatesSuccess, (state: InitiativeStatusUpdatesState, { StatusUpdates }) => {
    const entities = utility.ToEntities(StatusUpdates, 'id', {});

    return {
      ...state,
      entities,
      loadingFor: undefined,
      loadedFor: state.loadingFor,
    };
  }),
  on(actions.LoadStatusUpdatesFail, (state: InitiativeStatusUpdatesState, { error }) => {
    return {
      ...state,
      loadingFor: undefined,
      loadedFor: undefined,
      error,
    };
  }),
  on(actions.CreateStatusUpdate, (state: InitiativeStatusUpdatesState) => {
    return {
      ...state,
      saved: false,
      saving: true,
    };
  }),
  on(actions.CreateStatusUpdateSuccess, (state: InitiativeStatusUpdatesState, { StatusUpdate }) => {
    const entities = {
      ...state.entities,
      [StatusUpdate.id]: StatusUpdate,
    };
    return {
      ...state,
      entities,
      saved: true,
      saving: false,
    };
  }),
  on(actions.DeleteStatusUpdateSuccess, (state: InitiativeStatusUpdatesState, { StatusUpdate }) => {
    const { [StatusUpdate.id]: removed, ...entities } = state.entities;
    return {
      ...state,
      entities,
      saved: true,
      saving: false,
    };
  }),
  on(actions.ClearStatusUpdates, (state: InitiativeStatusUpdatesState) => {
    return {
      ...initialState,
    };
  })
);

export const getInitiativeStatusUpdatesEntities = (state: InitiativeStatusUpdatesState) => state.entities;
export const getInitiativeStatusUpdatesLoadingFor = (state: InitiativeStatusUpdatesState) => state.loadingFor;
export const getInitiativeStatusUpdatesLoadedFor = (state: InitiativeStatusUpdatesState) => state.loadedFor;
export const getInitiativeStatusUpdatesaved = (state: InitiativeStatusUpdatesState) => state.saved;
export const getInitiativeStatusUpdatesaving = (state: InitiativeStatusUpdatesState) => state.saving;
