// tslint:disable:max-line-length
import { createAction, props } from '@ngrx/store';
import { PagedOverview } from 'src/app/core/models';
import { Initiative, InitiativeContributor, UpdateInitiativeData, VoteDirection } from '../../models';

export const LoadContributors = createAction('[Initiatives] Load Contributors', props<{ initiativeId: number }>());
export const LoadContributorsSuccess = createAction(
  '[Initiatives] Load Contributors Success',
  props<{ contributors: InitiativeContributor[]; initiativeId: number }>()
);
export const LoadContributorsFail = createAction('[Initiatives] Load Contributors Fail', props<{ error: any; initiativeId: number }>());

export const AddContribution = createAction('[Initiatives]Add Contribution', props<{ initiativeId: number; motivation: string }>());
export const AddContributionSuccess = createAction(
  '[Initiatives]Add Contribution Success',
  props<{ contributor: InitiativeContributor; initiativeId: number }>()
);
export const AddContributionFail = createAction('[Initiatives]Add Contribution Fail', props<{ error: any; initiativeId: number }>());
