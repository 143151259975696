import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/contributors.action';
import { switchMap, map, catchError, tap, withLatestFrom } from 'rxjs/operators';
import { InitiativesService } from '../../services';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Go } from '../../../store';
import { Store } from '@ngrx/store';
import { getInitiativesPage } from '../selectors';

@Injectable()
export class ContributorsEffects {
  constructor(private actions$: Actions, private service: InitiativesService, private sb: MatSnackBar) {}

  LoadContributors$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadContributors),
      switchMap(({ initiativeId }) =>
        this.service.getInitiativeContributors(initiativeId).pipe(
          map(contributors => actions.LoadContributorsSuccess({ contributors, initiativeId })),
          catchError(error => of(actions.LoadContributorsFail({ error, initiativeId })))
        )
      )
    );
  });

  AddContributor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.AddContribution),
      switchMap(({ initiativeId, motivation }) =>
        this.service.MakeInitiativeContribution(initiativeId, { message: motivation }).pipe(
          map(contributor => actions.AddContributionSuccess({ contributor, initiativeId })),
          catchError(error => of(actions.AddContributionFail({ error, initiativeId })))
        )
      )
    );
  });

  AddContributorSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.AddContributionSuccess),
        tap(({}) => {
          this.sb.open('Je bijdrage is verstuurd!', '');
        })
      );
    },
    { dispatch: false }
  );
}
