import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as authActions from '../actions/auth.action';
import { switchMap, map, catchError, tap, take } from 'rxjs/operators';
import { AuthService } from '../../services';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store/index';
import { getRouterState, Go } from '../../../store/index';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store,
    private router: Router,
    private sb: MatSnackBar
  ) {}

  checkAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.CheckAuth),
      switchMap(() =>
        this.authService.checkAuth().pipe(
          map(auth => authActions.CheckAuthSuccess({ auth })),
          catchError(error => of(authActions.CheckAuthFail({ error })))
        )
      )
    );
  });

  signIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.SignIn),
      switchMap(({ loginData }) =>
        this.authService.signIn(loginData).pipe(
          map(auth => authActions.SignInSuccess({ auth })),
          catchError(error => of(authActions.SignInFail({ error })))
        )
      )
    );
  });

  signInSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.SignInSuccess),
      tap(({ auth }) => {
        this.sb.open(`Ingelogd als ${auth?.user?.fullName}`, '');
      }),
      switchMap(() =>
        this.store
          .select(getRouterState)
          .pipe(take(1))
          .pipe(
            map(({ state }) => {
              return Go({ path: [state.queryParams['returnUrl'] || '/'] });
            })
          )
      )
    );
  });

  signOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.SignOut),
      switchMap(() =>
        this.authService.SignOut().pipe(
          map(auth => authActions.SignOutSuccess({ result: auth })),
          catchError(error => of(authActions.SignOutFail({ error })))
        )
      )
    );
  });

  signOutSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.SignOutSuccess),
        tap(() => (window.location.href = '/account/inloggen'))
      );
    },
    { dispatch: false }
  );

  register$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.Register),
      switchMap(({ registerData }) =>
        this.authService.register(registerData).pipe(
          map(result => authActions.RegisterSuccess({ result: result })),
          catchError(error => of(authActions.RegisterFail({ error })))
        )
      )
    );
  });

  // TODO: Remove this effect
  // registerSuccess$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(authActions.RegisterSuccess),
  //     tap(({ result }) => {
  //       this.sb.open(`Je registratie is voltooid!`, '');
  //     }),
  //     switchMap(() =>
  //       this.store
  //         .select(getRouterState)
  //         .pipe(take(1))
  //         .pipe(
  //           map(({ state }) => {
  //             return Go({ path: [state.queryParams['returnUrl'] || '/'] });
  //           })
  //         )
  //     )
  //   );
  // });

  confirmAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.ActivateAccount),
      switchMap(({ token, uid }) =>
        this.authService.confirmAccount(token, uid).pipe(
          map(auth => authActions.ActivateAccountSuccess({ result: auth })),
          catchError(error => of(authActions.ActivateAccountFail({ error })))
        )
      )
    );
  });

  updateAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.UpdateAccount),
      switchMap(({ accountData }) =>
        this.authService.updateAccount(accountData).pipe(
          map(profile => authActions.UpdateAccountSuccess({ profile })),
          catchError(error => of(authActions.UpdateAccountFail({ error })))
        )
      )
    );
  });

  updateAccountSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.UpdateAccountSuccess),
        tap(({ profile }) => {
          this.sb.open(`Profiel geüpdatet!`, '');
        })
      );
    },
    { dispatch: false }
  );

  updatePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.UpdatePassword),
      switchMap(({ passwordData }) =>
        this.authService.updatePassword(passwordData).pipe(
          map(result => authActions.UpdatePasswordSuccess({ result })),
          catchError(error => of(authActions.UpdatePasswordFail({ error })))
        )
      )
    );
  });

  updatePasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.UpdatePasswordSuccess),
        tap(({ result }) => {
          this.sb.open(`Wachtwoord geüpdatet!`, '');
        })
      );
    },
    { dispatch: false }
  );
}
