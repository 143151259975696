import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/status-updates.action';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { InitiativeStatusUpdatesService } from '../../services';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Go } from '../../../store';

@Injectable()
export class StatusUpdatesEffects {
  constructor(
    private actions$: Actions,
    private service: InitiativeStatusUpdatesService,
    private router: Router,
    private sb: MatSnackBar
  ) {}

  LoadStatusUpdates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadStatusUpdates),
      switchMap(({ id }) =>
        this.service.getInitiativeStatusUpdates(id).pipe(
          map(StatusUpdates => actions.LoadStatusUpdatesSuccess({ StatusUpdates })),
          catchError(error => of(actions.LoadStatusUpdatesFail({ error })))
        )
      )
    );
  });

  CreateStatusUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CreateStatusUpdate),
      switchMap(({ id, title, text }) =>
        this.service.createInitiativeStatusUpdate(id, title, text).pipe(
          map(StatusUpdate => actions.CreateStatusUpdateSuccess({ StatusUpdate })),
          catchError(error => of(actions.CreateStatusUpdateFail({ error })))
        )
      )
    );
  });

  DeleteStatusUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.DeleteStatusUpdate),
      switchMap(({ id, StatusUpdateId }) =>
        this.service.deleteInitiativeStatusUpdate(id, StatusUpdateId).pipe(
          map(StatusUpdate => actions.DeleteStatusUpdateSuccess({ StatusUpdate })),
          catchError(error => of(actions.DeleteStatusUpdateFail({ error })))
        )
      )
    );
  });

  createStatusUpdateSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.CreateStatusUpdateSuccess),
        tap(({}) => {
          this.sb.open('Statusupdate aangemaakt!', '');
        })
      );
    },
    { dispatch: false }
  );

  deleteInitiativeStatuspdateSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.DeleteStatusUpdateSuccess),
        tap(({}) => {
          this.sb.open('Statusupdate verwijderd!', '');
        })
      );
    },
    { dispatch: false }
  );
}
