<div class="topbar" [class.set--fixed]="fixed">
  <div class="container topbar__container">
    <h1 class="topbar__logo mb-0">
      <a [routerLink]="'/home'" aria-label="Blauwhaus" class="logo-link">
        <span class="sr-only">Blauwhaus</span>
        <img src="/assets/gfx/logo-blauwhaus.svg" />
      </a>
    </h1>

    <div class="ms-auto topbar__actions">
      <a class="button button-icon button--light shadow--right" [routerLink]="['/dashboard']">
        <fstr-icon anchor="icon-location"></fstr-icon>
      </a>

      <nav class="topbar__notify" *ngIf="loggedIn">
        <button
          class="topbar__notify__toggle button button-icon button--light shadow--right"
          [disabled]="!hasNotify"
          (click)="hasNotify ? (showNotifications = !showNotifications) : false"
          [class.open--notifications]="showNotifications"
        >
          <fstr-icon class="topbar__notify__toggle__bell" anchor="icon-bell"></fstr-icon>
          <span class="notification__count" *ngIf="hasNotify"></span>
        </button>

        <div class="topbar__notify__container shadow--right" *ngIf="hasNotify" [class.open--notifications]="showNotifications">
          <span class="arrow"></span>
          <ul class="topbar__notify__list">
            <li class="mb-1" *ngFor="let n of notifications">
              <button class="topbar__notify__list__button type-body-xs" (click)="read(n)">
                {{ n.message }}
                <footer class="mt-2 type-body-xs text-muted" *ngIf="false">
                  {{ n.date | date }}
                </footer>
              </button>
            </li>
          </ul>
        </div>
      </nav>

      <button class="topbar__toggle hamburger" [class.is--active]="menuActive" (click)="toggleOverlay.emit()">
        <!--<fstr-icon class="topbar__toggle__bg" anchor="icon-nav"></fstr-icon>-->
        <span class="hamburger__lines"></span>
      </button>
    </div>

    <nav class="topbar__nav">
      <!-- <img src="/assets/gfx/logo-blauwhaus.svg" /> -->
      <div class="container">
        <button class="button button-icon button--light topbar__nav__close" (click)="toggleOverlay.emit()">
          <fstr-icon anchor="icon-cross"></fstr-icon>
        </button>

        <ul class="topbar__nav__list">
          <li class="topbar__nav__list__item" *ngFor="let item of menu">
            <a
              [routerLink]="item.routerLink"
              [routerLinkActive]="'is--current'"
              [routerLinkActiveOptions]="{ exact: true }"
              class="button button-link button--dark button--lg"
            >
              {{ item.title }}
            </a>
          </li>

          <li class="topbar__nav__list__item" *ngIf="loggedIn">
            <button class="button button-link button--dark button--lg" (click)="signOut()">
              {{ 'uitloggen-label' | fromDictionary }}
            </button>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
<div class="topbar--spacer"></div>
