import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { Observable, of } from 'rxjs';
import { SetTitle } from '../../../store';
import { ActivateAccount, getAccountActivated, getAccountActivating, getAuthError } from '../../store';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
})
export class ActivateAccountComponent implements OnInit {
  prefix = 'account-activatie';
  activating$: Observable<boolean> = of(false);
  activated$: Observable<boolean> = of(false);
  error$?: Observable<any>;

  private dict = new FromDictionaryPipe(this.lang);

  constructor(private store: Store, private route: ActivatedRoute, private lang: LanguageService) {}

  ngOnInit(): void {
    this.activated$ = this.store.select(getAccountActivated);
    this.activating$ = this.store.select(getAccountActivating);
    this.error$ = this.store.select(getAuthError);
    this.store.dispatch(
      SetTitle({ title: `${this.dict.transform('account-activatie-titel')} - ${this.dict.transform('meta-titel-suffix')}` })
    );
    this.activateAccount(this.route.snapshot.queryParams['token'], this.route.snapshot.queryParams['uid']);
  }

  activateAccount(token: string, uid: string) {
    this.store.dispatch(ActivateAccount({ token, uid }));
  }
}
