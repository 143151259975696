<article class="alert" role="alert" [ngClass]="status">
  <div class="alert__inner">
    <header class="alert__header mb-2" *ngIf="title">
      <fstr-icon class="icon-chip icon-chip--light me-2" *ngIf="alertIcon" [anchor]="alertIcon"></fstr-icon>
      <h2 class="h6 alert__title mb-0">{{ title }}</h2>
    </header>
    <div class="alert__content markdown-content">
      <p *ngIf="message">
        <strong>{{ message }}</strong>
      </p>
      <ng-content></ng-content>
    </div>
  </div>
</article>
