import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { DictionaryNgrxModule, DictionaryGuard, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { reducers, CustomSerializer, appEffects, metaReducers } from './store';

import localeNl from './nl';
import { registerLocaleData } from '@angular/common';

// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotFoundComponent } from './404.component';
import { CookieConfig, CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { DynamicFormConfig, LegacyDynamicFormModule as DynamicFormModule } from '@teamfoster/sdk/legacy-dynamic-form';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthModule } from './auth/auth.module';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MenuGuard, MenuNgrxModule } from '@teamfoster/sdk/menu-ngrx';
import { FormFileInputComponent } from './core/form-fields/form-file-input/form-file-input.component';

import { FormCheckboxButtonsComponent } from './core/form-fields/form-checkbox-buttons/form-checkbox-buttons.component';
import { MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';
import { NotificationsModule } from './notifications/notifications.module';

const dynamicFormConfig: Partial<DynamicFormConfig> = {
  genericInputErrorMessage: 'Het formulier is niet correct ingevuld, controleer de velden en probeer nogmaals.',
  genericServerErrorMessage:
    'Er is iets fout gegaan bij het versturen van het formulier, probeer het later nogmaals. Blijft het probleem voordoen neem dan contact met ons op.',
  formControls: {
    file: FormFileInputComponent,
    checkboxButtons: FormCheckboxButtonsComponent,
  },
};

const cookieConfig: Partial<CookieConfig> = {
  analyticsCode: 'G-4N43HX6WJQ',
  strippedContentPlaceholder: 'Deze inhoud wordt niet getoond omdat je geen marketing cookies hebt geaccepteerd',
  buttonSettings: 'Instellingen',
  buttonAcceptAll: 'Alles accepteren',
  buttonClose: 'Sluiten',
  useTagManager: true,
  strippedContentButton: 'Cookie-instellingen wijzigen',
  types: {
    analytical: true,
    functional: true,
    marketing: false,
  },
  showSettings: true,
};

registerLocaleData(localeNl, 'nl');
@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    CoreModule,
    IconModule.forRoot({ spritesheetUrl: 'assets/dist/svg/symbol/svg/sprite.symbol.svg' }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(appEffects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    DictionaryNgrxModule.forRoot({}),
    CookieNgrxModule.forRoot(cookieConfig),
    DynamicFormModule.forRoot(dynamicFormConfig),
    MenuNgrxModule.forRoot({}),
    BrowserAnimationsModule,
    AuthModule,
    NotificationsModule,
    HammerModule,
  ],
  providers: [
    LanguageService,
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 1500, panelClass: ['bg-success'], hasAction: false } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-icons-outlined');
  }
}
