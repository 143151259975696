import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, Inject, PLATFORM_ID, InjectionToken } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import * as fromAuth from '../../../auth/store';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { Store } from '@ngrx/store';
import { NotificationBundle } from 'src/app/notifications/models';
import { MarkAsRead } from 'src/app/notifications/store';
import { Go } from 'src/app/store';
import { loremNotification } from './_lorem-noties';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
})
export class TopbarComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() menuActive: boolean = false;
  @Input() logoClass: string = '';
  @Input() menu: fromMenu.MenuItem[] = [];
  @Input() loggedIn: boolean = false;
  @Input() notifications!: NotificationBundle[] | null;

  @Output() toggleOverlay = new EventEmitter<void>();
  @Output() closeOverlay = new EventEmitter<void>();
  @Output() openOverlay = new EventEmitter<void>();

  fixed = false;
  showNotifications = false;

  get hasNotify() {
    if (this.notifications && this.notifications.length) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    private router: Router,
    private store: Store
  ) {
    router.events.pipe(takeUntilDestroyed()).subscribe(s => {
      if (s instanceof NavigationStart && this.menuActive) {
        this.closeOverlay.emit();
      }
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(window, 'scroll')
        .pipe(debounceTime(20))
        .subscribe(a => {
          // show scrollbar after windowinnerheight (window.innerHeight)
          if (window.scrollY > 96) {
            this.fixed = true;
          } else {
            this.fixed = false;
          }
          this.cd.detectChanges();
        });
    }
  }

  signOut() {
    this.store.dispatch(fromAuth.SignOut());
  }

  read(notification: NotificationBundle) {
    this.showNotifications = false;
    this.store.dispatch(MarkAsRead({ notification }));
    this.store.dispatch(Go({ path: notification.routerLink }));
  }
}
