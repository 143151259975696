import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LanguageService } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateComponent {
  @Input() start!: Date | string;
  @Input() end?: Date | string;
  @Input() size?: 'sm' | 'lg';
  @Input() orientation?: 'horizontal' | 'vertical';

  constructor(private lang: LanguageService) {}

  getMonth(d: Date | string) {
    if (!d) {
      return;
    }
    let date = typeof d === 'string' ? new Date(d) : d;
    return date.toLocaleString(this.lang.getLanguage() || 'nl', { month: 'short' });
  }

  isCurrentYear(d: Date | string) {
    if (!d) {
      return;
    }
    let date = typeof d === 'string' ? new Date(d) : d;
    return new Date().getFullYear() === date.getFullYear();
  }
}
