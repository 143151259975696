import { createReducer, on } from '@ngrx/store';
import { InitiativeContributor } from '../../models';
import * as utility from '../../../utility';
import * as ContributorActions from '../actions/contributors.action';
import { PageData } from 'src/app/core/models';

export interface ContributorsState {
  entities: { [id: number]: InitiativeContributor[] };
  loading: number[];
  loaded: number[];
  contributed: number[];
  error?: any;
}

export const initialState: ContributorsState = {
  entities: {},
  loaded: [],
  loading: [],
  contributed: [],
};

export const ContributorReducer = createReducer(
  initialState,
  on(ContributorActions.LoadContributors, (state: ContributorsState, { initiativeId }) => {
    return {
      ...state,
      loading: [...state.loading, initiativeId],
    };
  }),
  on(ContributorActions.LoadContributorsSuccess, (state: ContributorsState, { contributors, initiativeId }) => {
    const entities = {
      ...state.entities,
      [initiativeId]: [...contributors],
    };

    return {
      ...state,
      entities,
      loading: [...state.loading.filter(a => a !== initiativeId)],
      loaded: [...state.loaded, initiativeId],
    };
  }),
  on(ContributorActions.LoadContributorsFail, (state: ContributorsState, { error, initiativeId }) => {
    return {
      ...state,
      loading: [...state.loading.filter(a => a !== initiativeId)],
      loaded: [...state.loaded],
      error,
    };
  }),
  on(ContributorActions.AddContributionSuccess, (state: ContributorsState, { initiativeId, contributor }) => {
    const entities = {
      ...state.entities,
      [initiativeId]: [...state.entities[initiativeId], contributor],
    };

    return {
      ...state,
      entities,
      contributed: [...state.contributed, initiativeId],
    };
  })
);

export const getContributorsEntities = (state: ContributorsState) => state.entities;
export const getContributorsLoading = (state: ContributorsState) => state.loading;
export const getContributorsLoaded = (state: ContributorsState) => state.loaded;
export const getContributorsContributed = (state: ContributorsState) => state.contributed;
