import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { DynamicFormField } from '@teamfoster/sdk/dynamic-form';
import { SetTitle } from '../../../store';
import { AuthService } from '../../services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  prefix = 'wachtwoord-vergeten';
  loading = false;
  result?: { succeeded: boolean; error?: string };

  checkValidation = false;
  private dict = new FromDictionaryPipe(this.lang);

  forgotPasswordForm = this.fb.group({});
  formFields: DynamicFormField[] = [];

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private cd: ChangeDetectorRef,
    private store: Store,
    private lang: LanguageService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      SetTitle({ title: `${this.dict.transform(this.prefix + '-titel')} - ${this.dict.transform('meta-titel-suffix')}` })
    );

    this.formFields = [
      {
        inputType: 'email',
        label: this.dict.transform(`${this.prefix}-email-label`),
        name: 'email',
        validators: [Validators.email, Validators.required],
        autocomplete: 'email',
        placeholder: this.dict.transform(`${this.prefix}-email-placeholder`),
        fieldType: 'input',
        order: 0,
      },
    ];
  }

  async requestPasswordReset(val: any) {
    this.checkValidation = true;
    this.loading = true;
    this.result = await this.auth.forgotPassword(val.email).toPromise();
    this.loading = false;

    this.cd.detectChanges();
  }
}
