<nav class="tab-bar shadow--right">
  <ul class="tab-bar__list d-flex">
    <li class="tab-bar__list__item">
      <a [routerLink]="['/meningen']" [routerLinkActive]="'is--current'" [routerLinkActiveOptions]="{ exact: false }" class="tab-bar__tab">
        <fstr-icon class="icon-lg icon-tabbar-meningen" anchor="icon-tabbar-meningen"></fstr-icon>
        <fstr-icon class="icon-m" anchor="icon-m"></fstr-icon>
        <strong class="type-body-xxs">Meningen</strong>
      </a>
    </li>
    <li class="tab-bar__list__item type--action">
      <a [routerLink]="[actionTabUrl]" [routerLinkActive]="'is--current'" [routerLinkActiveOptions]="{ exact: true }" class="tab-bar__tab">
        <fstr-icon class="" anchor="icon-tabbar-add"></fstr-icon>
        <fstr-icon class="icon-add" anchor="icon-cross"></fstr-icon>
        <strong class="type-body-xxs">{{ actionTabLabel }}</strong>
      </a>
    </li>
    <li class="tab-bar__list__item">
      <a
        [routerLink]="['/initiatieven']"
        [routerLinkActive]="'is--current'"
        [routerLinkActiveOptions]="{ exact: false }"
        class="tab-bar__tab"
      >
        <fstr-icon class="icon-lg icon-tabbar-initiatieven" anchor="icon-tabbar-initiatieven"></fstr-icon>
        <fstr-icon class="icon-i" anchor="icon-i"></fstr-icon>
        <strong class="type-body-xxs">Initiatieven</strong>
      </a>
    </li>
  </ul>
</nav>
<div class="tab-bar__spacer"></div>
