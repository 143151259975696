import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/initiatives.action';
import { switchMap, map, catchError, tap, withLatestFrom, filter } from 'rxjs/operators';
import { InitiativesService } from '../../services';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Go, getRouterState } from '../../../store';
import { Store } from '@ngrx/store';
import { getInitiativesPage } from '../selectors';
import { getAuthSignedIn } from 'src/app/auth/store';

@Injectable()
export class InitiativesEffects {
  constructor(
    private actions$: Actions,
    private service: InitiativesService,
    private router: Router,
    private sb: MatSnackBar,
    private store: Store
  ) {}

  LoadInitiative$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadInitiative),
      switchMap(({ id }) =>
        this.service.getInitiative(id).pipe(
          map(initiative => actions.LoadInitiativeSuccess({ initiative })),
          catchError(error => of(actions.LoadInitiativeFail({ error })))
        )
      )
    );
  });

  LoadInitiatives$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadInitiatives),
      switchMap(({ skip, take, category, unvoted, personalized }) =>
        this.service.getInitiatives(skip, take, category, unvoted, personalized).pipe(
          map(initiatives => actions.LoadInitiativesSuccess({ initiatives })),
          catchError(error => of(actions.LoadInitiativesFail({ error })))
        )
      )
    );
  });

  LoadMoreInitiatives$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadMoreInitiatives),
      withLatestFrom(this.store.select(getInitiativesPage)),
      switchMap(([{}, pagedata]) =>
        this.service
          .getInitiatives(
            (pagedata.current + 1) * pagedata.size,
            pagedata.size,
            undefined,
            pagedata.filters?.['unvoted'],
            pagedata.filters?.['personalized']
          )
          .pipe(
            map(initiatives => actions.LoadMoreInitiativesSuccess({ initiatives })),
            catchError(error => of(actions.LoadInitiativesFail({ error })))
          )
      )
    );
  });

  CreateInitiative$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CreateInitiative),
      switchMap(({ initiativeData }) =>
        this.service.createInitiative(initiativeData).pipe(
          map(initiative => actions.CreateInitiativeSuccess({ initiative })),
          catchError(error => of(actions.CreateInitiativeFail({ error })))
        )
      )
    );
  });

  UpdateInitiative$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.UpdateInitiative),
      switchMap(({ id, initiativeData }) =>
        this.service.updateInitiative(id, initiativeData).pipe(
          map(initiative => actions.UpdateInitiativeSuccess({ initiative })),
          catchError(error => of(actions.UpdateInitiativeFail({ error })))
        )
      )
    );
  });

  DeleteInitiative$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.DeleteInitiative),
      switchMap(({ id }) =>
        this.service.deleteInitiative(id).pipe(
          map(initiative => actions.DeleteInitiativeSuccess({ initiative })),
          catchError(error => of(actions.DeleteInitiativeFail({ error })))
        )
      )
    );
  });

  CloseInitiative$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CloseInitiative),
      switchMap(({ id }) =>
        this.service.closeInitiative(id).pipe(
          map(initiative => actions.CloseInitiativeSuccess({ initiative })),
          catchError(error => of(actions.CloseInitiativeFail({ error })))
        )
      )
    );
  });

  CastVote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CastVote),
      withLatestFrom(this.store.select(getAuthSignedIn)),
      filter(([{}, signedIn]) => signedIn),
      switchMap(([{ id, direction }, signedIn]) =>
        this.service.castVote(id, direction).pipe(
          map(initiative => actions.CastVoteSuccess({ initiative })),
          catchError(error => of(actions.CastVoteFail({ error })))
        )
      )
    );
  });

  CastVoteUnauthorized$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CastVote),
      withLatestFrom(this.store.select(getAuthSignedIn), this.store.select(getRouterState)),
      filter(([{}, signedIn]) => !signedIn),
      map(([{}, signedIn, router]) => Go({ path: ['/account', 'inloggen'], queryParams: { returnUrl: router.state.url } }))
    );
  });

  updateInitiativeSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.UpdateInitiativeSuccess),
      tap(({ initiative }) => {
        this.sb.open('Initiatief geüpdatet!', '');
      }),
      map(({ initiative }) => Go({ path: initiative.url }))
    );
  });

  createInitiativeSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CreateInitiativeSuccess),
      tap(({ initiative }) => {
        this.sb.open('Initiatief aangemaakt!', '');
      }),
      map(({ initiative }) => Go({ path: initiative.url }))
    );
  });

  deleteInitiativeSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.DeleteInitiativeSuccess),
      tap(({ initiative }) => {
        this.sb.open('Initiatief verwijderd!', '');
      }),
      map(({ initiative }) => Go({ path: ['/', 'initiatieven'] }))
    );
  });

  closeInitiativeSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CloseInitiativeSuccess),
      tap(({ initiative }) => {
        this.sb.open('Initiatief gesloten!', '');
      }),
      map(({ initiative }) => Go({ path: initiative.url }))
    );
  });

  castVoteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.CastVoteSuccess),
        tap(({ initiative }) => {
          this.sb.open('Jouw stem is uitgebracht!', '');
        })
      );
    },
    { dispatch: false }
  );

  CreateInitiativeFromOpinion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CreateInitiativeFromOpinion),
      switchMap(({ opinionId }) =>
        this.service.createInitiativeFromOpinion(opinionId).pipe(
          map(initiative => actions.CreateInitiativeSuccess({ initiative })),
          catchError(error => of(actions.CreateInitiativeFail({ error })))
        )
      )
    );
  });
}
