import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  @Input() status: string = '';
  @Input() title?: string = '';
  @Input() message?: string;

  get alertIcon() {
    switch (this.status) {
      case 'type--error':
        return 'icon-error';

      case 'type--succes':
        return 'icon-check';

      case 'type--warning':
        return 'icon-warning';

      case 'type--danger':
        return 'icon-error';

      default:
        return '';
    }
  }

  constructor() {}
}
