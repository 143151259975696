import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Opinion, UpdateOpinionData } from '../models/opinion.model';
import { PagedOverview } from 'src/app/core/models';
import { VoteDirection } from '../models';

@Injectable({
  providedIn: 'root',
})
export class OpinionsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getOpinions(skip: number = 0, take: number = 0, category: number = 0, unvoted: boolean = false, personalized: boolean = false) {
    return this.http.get<PagedOverview<Opinion>>(
      `${this.BASE_URL}api/opinions?take=${take}&skip=${skip}&category=${category}&unvoted=${unvoted}&personalized=${personalized}`
    );
  }

  getOpinion(id: number) {
    return this.http.get<Opinion>(`${this.BASE_URL}api/opinions/${id}`);
  }

  updateOpinion(id: number, data: UpdateOpinionData) {
    const formData: FormData = new FormData();
    if (data.image instanceof Blob) {
      formData.append('image', data.image);
    } else {
      formData.append('image', <Blob>(null as unknown));
    }
    formData.append('title', data.title);
    formData.append('text', data.text);
    formData.append('helpText', data.helpText);

    data.categories.forEach((category, i) => {
      formData.append(`categories[${i}].id`, category.id.toString());
      formData.append(`categories[${i}].title`, category.title);
    });
    formData.append('id', data.id?.toString());
    return this.http.put<Opinion>(`${this.BASE_URL}api/opinions/${id}`, formData);
  }

  deleteOpinion(id: number) {
    return this.http.delete<Opinion>(`${this.BASE_URL}api/opinions/${id}`);
  }

  closeOpinion(id: number) {
    return this.http.post<Opinion>(`${this.BASE_URL}api/opinions/${id}/close`, null);
  }

  castVote(id: number, direction: VoteDirection) {
    return this.http.post<Opinion>(`${this.BASE_URL}api/opinions/${id}/vote`, direction);
  }

  createOpinion(data: UpdateOpinionData) {
    const formData: FormData = new FormData();
    if (data.image instanceof Blob) {
      formData.append('image', data.image);
    } else {
      formData.append('image', <Blob>(null as unknown));
    }
    formData.append('title', data.title);
    formData.append('text', data.text);
    data.categories.forEach((category, i) => {
      formData.append(`categories[${i}].id`, category.id.toString());
      formData.append(`categories[${i}].title`, category.title);
    });
    formData.append('id', data.id?.toString());
    return this.http.post<Opinion>(`${this.BASE_URL}api/opinions`, formData);
  }
}
