import * as fromOpinion from './opinions.reducer';
import * as fromComments from './comments.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'Opinions';

export interface OpinionState {
  opinions: fromOpinion.OpinionsState;
  comments: fromComments.CommentsState;
}

export const reducers: ActionReducerMap<OpinionState> = {
  opinions: fromOpinion.OpinionReducer,
  comments: fromComments.CommentReducer,
};

export const getOpinionsState = createFeatureSelector<OpinionState>(featureKey);
export const metaReducers: MetaReducer<OpinionState>[] = !environment.production ? [] : [];
