import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromOpinion from '../reducers/opinions.reducer';
import * as fromRoot from '../../../store';
import * as fromAuth from '../../../auth/store';
import { Opinion } from '../../models';

export const getOpinionState = createSelector(fromFeature.getOpinionsState, (state: fromFeature.OpinionState) => state.opinions);

export const getOpinionsEntities = createSelector(getOpinionState, fromOpinion.getOpinionsEntities);

export const getAllOpinions = createSelector(getOpinionsEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

export const getMyOpinions = createSelector(getAllOpinions, fromAuth.getAuthUser, (opinions, usr) => {
  return opinions?.filter(a => a.createdById === usr?.id);
});

export const getOpinionsLoaded = createSelector(getOpinionState, fromOpinion.getOpinionsLoaded);
export const getOpinionsLoading = createSelector(getOpinionState, fromOpinion.getOpinionsLoading);
export const getOpinionSaved = createSelector(getOpinionState, fromOpinion.getOpinionSaved);
export const getOpinionSaving = createSelector(getOpinionState, fromOpinion.getOpinionSaving);
export const getOpinionsPage = createSelector(getOpinionState, fromOpinion.getOpinionsPage);

export const getOrderedOpinions = createSelector(getAllOpinions, getOpinionsPage, (Pages, page) => {
  let orderedPages = Pages.sort((a, b) => (new Date(b.createdOn) > new Date(a.createdOn) ? 1 : -1));
  if (page.filters['personalized']) {
    orderedPages = Pages.sort((a, b) => (b.score > a.score ? 1 : -1));
  }
  return orderedPages;
});

export const getUnvotedOpinions = createSelector(getOrderedOpinions, fromAuth.getAuthUser, (opinions, usr) => {
  return opinions?.filter(a => !a.votes.mine);
});

export const getSelectedOpinion = createSelector(getOpinionsEntities, fromRoot.getRouterState, (entities, router): Opinion => {
  return router.state && entities[router.state.params?.['opinionId'] || router.state.data?.['id']];
});

export const getSelectedOpinionLoaded = createSelector(getSelectedOpinion, (c: Opinion) => {
  return c?.loaded;
});

export const getSelectedOpinionLoading = createSelector(getSelectedOpinion, (c: Opinion) => {
  return c?.loading;
});
