import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromComments from '../reducers/comments.reducer';
import * as fromOpinions from '../selectors/opinions.selector';
import { Opinion } from '../../models';

export const GetCommentstate = createSelector(fromFeature.getOpinionsState, (state: fromFeature.OpinionState) => state.comments);

export const GetCommentsEntities = createSelector(GetCommentstate, fromComments.getCommentsEntities);

export const getAllComments = createSelector(GetCommentsEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

export const getOrderedComments = createSelector(getAllComments, comments => {
  return comments.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
});

export const GetCommentsLoadedFor = createSelector(GetCommentstate, fromComments.getCommentsLoadedFor);
export const GetCommentsLoadingFor = createSelector(GetCommentstate, fromComments.getCommentsLoadingFor);
export const GetCommentsaved = createSelector(GetCommentstate, fromComments.getCommentSaved);
export const GetCommentsaving = createSelector(GetCommentstate, fromComments.getCommentSaving);

export const getSelectedOpinionCommentsLoaded = createSelector(
  GetCommentsLoadedFor,
  fromOpinions.getSelectedOpinion,
  (loadedFor: number | undefined, o: Opinion) => {
    return o?.id === loadedFor;
  }
);

export const getSelectedOpinionCommentsLoading = createSelector(
  GetCommentsLoadingFor,
  fromOpinions.getSelectedOpinion,
  (loadingFor: number | undefined, o: Opinion) => {
    return o?.id === loadingFor;
  }
);
