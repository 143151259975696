import { createReducer, on } from '@ngrx/store';
import * as utility from '../../../utility';
import * as CommentActions from '../actions/comments.action';
import { PageData } from 'src/app/core/models';
import { InitiativeComment } from '../../models';

export interface CommentsState {
  entities: { [id: number]: InitiativeComment };
  loadingFor?: number;
  loadedFor?: number;
  saving: boolean;
  saved: boolean;
  error?: any;
}

export const initialState: CommentsState = {
  entities: {},
  loadedFor: undefined,
  loadingFor: undefined,
  saved: false,
  saving: false,
};

export const CommentReducer = createReducer(
  initialState,
  on(CommentActions.LoadComments, (state: CommentsState, { id }) => {
    return {
      ...state,
      loadingFor: +id,
    };
  }),
  on(CommentActions.LoadCommentsSuccess, (state: CommentsState, { comments }) => {
    const entities = utility.ToEntities(comments, 'id', {});

    return {
      ...state,
      entities,
      loadingFor: undefined,
      loadedFor: state.loadingFor,
    };
  }),
  on(CommentActions.LoadCommentsFail, (state: CommentsState, { error }) => {
    return {
      ...state,
      loadingFor: undefined,
      loadedFor: undefined,
      error,
    };
  }),
  on(CommentActions.CreateComment, (state: CommentsState) => {
    return {
      ...state,
      saved: false,
      saving: true,
    };
  }),
  on(CommentActions.CreateCommentSuccess, (state: CommentsState, { comment }) => {
    const entities = {
      ...state.entities,
      [comment.id]: comment,
    };
    return {
      ...state,
      entities,
      saved: true,
      saving: false,
    };
  }),
  on(CommentActions.DeleteCommentSuccess, (state: CommentsState, { comment }) => {
    const { [comment.id]: removed, ...entities } = state.entities;
    return {
      ...state,
      entities,
      saved: true,
      saving: false,
    };
  }),
  on(CommentActions.ClearComments, (state: CommentsState) => {
    return {
      ...initialState,
    };
  })
);

export const getCommentsEntities = (state: CommentsState) => state.entities;
export const getCommentsLoadingFor = (state: CommentsState) => state.loadingFor;
export const getCommentsLoadedFor = (state: CommentsState) => state.loadedFor;
export const getCommentSaved = (state: CommentsState) => state.saved;
export const getCommentSaving = (state: CommentsState) => state.saving;
