<ul>
  <li *ngFor="let n of notifications">
    <button [class.fw-bold]="!n.isRead" (click)="read(n)">{{ n.message }}</button>
  </li>
</ul>

<section class="theme-updates page page--updates-overview bg-secondary overflow-hidden">
  <div class="section-inset-y container container-size-xxs">
    <header class="section-header">
      <fstr-icon [class]="'page-title mb-3 aspect-icon-updates'" [anchor]="'updates'"></fstr-icon>

      <div class="section-header__background">
        <fstr-icon class="section-header__background__icon aspect-icon-icon-dash" anchor="icon-dash"></fstr-icon>
      </div>

      <div class="section-header__content">
        <h2 class="h1 text-white">Updates</h2>
      </div>
      <div class="section-content">
        <div class="card__content">
          <ul>
            <li class="mb-3" *ngFor="let n of notifications">
              <app-notification-card [notification]="n"></app-notification-card>
            </li>
          </ul>
        </div>
      </div>
    </header>
  </div>
</section>
