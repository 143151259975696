import { Component, OnInit, Inject, PLATFORM_ID, Renderer2, OnDestroy, InjectionToken, HostListener, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet, NavigationStart } from '@angular/router';
import { Store } from '@ngrx/store';
import { DictionaryState, getDictionaryLoaded } from '@teamfoster/sdk/dictionary-ngrx';
import { Observable, BehaviorSubject, timer, of, Subject, combineLatest } from 'rxjs';
import { ContentPage } from './content/models';

import * as navActions from './store/actions/nav.action';
import * as navSelectors from './store/selectors/nav.selector';
import { SetTheme } from 'src/app/store';
import { tap, take, filter, withLatestFrom, takeUntil, map } from 'rxjs/operators';
import { routerFade } from './animations';
import { ChangeDetectionStrategy } from '@angular/core';
import { CookieConfig, CookieConfigService, CookiePreferences, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { getRouterState, RouterStateUrl } from './store';
import { RouterReducerState } from '@ngrx/router-store';
import { getMenuLoaded } from '@teamfoster/sdk/menu-ngrx';
import { getAuthSignedIn, getUser } from './auth/store';
import { getAllNotifications, getAllUnreadedNotifications } from './notifications/store';
import { NotificationBundle } from './notifications/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [routerFade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  loading$ = new BehaviorSubject<boolean>(true);
  loaded$!: Observable<boolean>;
  routeState$!: Observable<RouterReducerState<RouterStateUrl>>;
  menuOpen$!: Observable<boolean>;
  menuItems$!: Observable<ContentPage[]>;
  cookiePrefs$!: Observable<CookiePreferences | null>;
  domainName: string = '';
  testGridEnabled = false;
  routerAnimationState = '';
  theme$!: Observable<string>;
  tabActionOpinion = true;
  usr$!: Observable<any>;
  notifications$!: Observable<NotificationBundle[]>;

  private previousPath = '';
  private _unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    private router: Router,
    private store: Store,
    private renderer: Renderer2,
    @Inject(CookieConfigService) private cookieConfig: CookieConfig
  ) {}

  ngOnInit(): void {
    this.cookiePrefs$ = this.store.select(getCookiePreferences);
    this.usr$ = this.store.select(getAuthSignedIn);
    this.notifications$ = this.store.select(getAllUnreadedNotifications);
    this.loaded$ = combineLatest([this.store.select(getDictionaryLoaded), this.store.select(getMenuLoaded)]).pipe(
      map(([dictionaryLoaded, menuLoaded]) => dictionaryLoaded && menuLoaded)
    );
    this.menuOpen$ = this.store.select(navSelectors.getMenuOpen).pipe(tap(a => this.updateRoot(a)));
    this.theme$ = this.store.select(navSelectors.getTheme);
    this.routeState$ = this.store.select(getRouterState);
    this.domainName = this.document.location.hostname;
    this.router.events
      .pipe(withLatestFrom(this.cookiePrefs$, this.routeState$), takeUntil(this._unsubscribe$))
      .subscribe(([event, cookies, state]) => {
        if (event instanceof NavigationStart) {
          this.loading$.next(true);
        } else if (event instanceof NavigationEnd) {
          this.closeMenu();
          this.store.dispatch(SetTheme({ theme: state?.state?.data['theme'] ? state?.state?.data['theme'] : 'theme-meningen' }));
          this.tabActionOpinion = state?.state?.data['tabActionOpinion'];
          this.loading$.next(false);
        }

        if (
          isPlatformBrowser(this.platformId) &&
          event instanceof NavigationStart &&
          (event as NavigationStart).navigationTrigger === 'imperative'
        ) {
          if (!state?.state?.queryParams) {
            window.scrollTo(0, 0);
          }
        }

        if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd && cookies?.analytical) {
          if ((<any>window).gtag) {
            (<any>window).gtag('config', this.cookieConfig.analyticsCode, { page_path: event.urlAfterRedirects, anonymize_ip: true });
          }
        }
      });

    if (isPlatformBrowser(this.platformId)) {
      this.document.querySelector('body').classList.add('set--in');
      this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }

  toggleMenu() {
    this.store.dispatch(navActions.ToggleMenu());
  }

  closeMenu() {
    this.store.dispatch(navActions.CloseMenu());
  }

  openMenu() {
    this.store.dispatch(navActions.OpenMenu());
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  private updateRoot(menuActive: boolean) {
    menuActive
      ? this.renderer.addClass(this.document.body, 'main-nav--active')
      : this.renderer.removeClass(this.document.body, 'main-nav--active');
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
