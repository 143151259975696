import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuGuard } from '@teamfoster/sdk/menu-ngrx';
import { NotFoundComponent } from './404.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { CheckAuthGuard } from './auth/guards/checkAuth.guard';
import { InitiativesOverviewGuard } from './initiatives/guards/initiatives.guard';
import { NotificationsGuard } from './notifications/guards';
import { OpinionsAllOverviewGuard } from './opinions/guards/opinions-all.guard';
import { EventsAllOverviewGuard } from './events/guards/events-all.guard';

const appGuards = [DictionaryGuard, CookieSettingsGuard, CheckAuthGuard, MenuGuard, NotificationsGuard];
// const signedInGuards = [AuthGuard, NotificationsGuard]; // only visible for signed in members

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./activity-feed/activity-feed.module').then(m => m.ActivityFeedModule),
    pathMatch: 'full',
    canActivate: [...appGuards, AuthGuard],
    data: {
      theme: 'theme-meningen',
      tabActionOpinion: true,
    },
  },
  {
    path: 'styleguide',
    loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule),
    canActivate: [...appGuards],
  },
  {
    path: 'pagina',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    canActivate: [...appGuards],
    data: {
      theme: 'theme-meningen',
      tabActionOpinion: true,
    },
  },
  {
    path: 'account',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [...appGuards],
    data: {
      theme: 'theme-meningen',
      tabActionOpinion: true,
    },
  },
  {
    path: 'mijn-profiel',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [...appGuards, AuthGuard],
    data: {
      theme: 'theme-inloggen',
      tabActionOpinion: true,
    },
  },
  {
    path: 'meningen',
    loadChildren: () => import('./opinions/opinions.module').then(m => m.OpinionsModule),
    canActivate: [...appGuards],
    canDeactivate: [OpinionsAllOverviewGuard],
    data: {
      theme: 'theme-meningen',
      tabActionOpinion: true,
    },
  },
  {
    path: 'initiatieven',
    loadChildren: () => import('./initiatives/initiatives.module').then(m => m.InitiativesModule),
    canActivate: [...appGuards],
    canDeactivate: [InitiativesOverviewGuard],
    data: {
      theme: 'theme-initiatieven',
      tabActionOpinion: false,
    },
  },
  {
    path: 'agenda',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
    canActivate: [...appGuards],
    canDeactivate: [EventsAllOverviewGuard],
    data: {
      theme: 'theme-agenda',
      tabActionOpinion: false,
    },
  },
  {
    path: '',
    pathMatch: 'prefix',
    loadChildren: () => import('./landing-pages/landing-pages.module').then(m => m.LandingPagesModule),
    canActivate: [...appGuards],
    data: {
      theme: 'palette-dark',
    },
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
