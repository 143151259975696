<div class="bg-secondary theme-inloggen">
  <section class="auth">
    <div class="auth__inner container container-size-xxs">
      <header class="auth__header">
        <a [routerLink]="'/account/inloggen'"><img class="logo" src="/assets/gfx/logo-blauwhaus.svg" /></a>
        <fstr-icon [class]="'page-title mb-3 aspect-icon-' + icon" [anchor]="icon"></fstr-icon>
      </header>

      <main class="auth__main bg-primary">
        <div class="card shadow--right shadow-md">
          <div class="card__header has--border">
            <h1 class="h5 card__title mb-0">{{ title }}</h1>
          </div>
          <div class="card__content">
            <ng-content select="[form]"></ng-content>
          </div>
        </div>
      </main>

      <footer class="auth__footer text-light">
        <ng-content select="[footer]"></ng-content>
      </footer>
    </div>
  </section>
</div>
